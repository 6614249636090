<template>
  <div class="bg-gray-900 mx-8 mt-4 text-gray-100">
    <div v-if="!storeState.cards.ok || !storeState.card.details.title">
      <div class="flex justify-center space-y-8 w-full pt-32">
        <svg class="animate-spin -ml-1 mr-3 h-20 w-20 text-gray-100" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path 
            class="opacity-75" 
            fill="currentColor" 
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
          </path>
        </svg>
      </div>
    </div>
    <div ref="mainContainer" v-else class="">
      <div class="flex justify-start">
        <div>
          <img 
            class="w-auto h-6 cursor-pointer hover:opacity-50" 
            src="/wax-logo.png" 
            alt="Logo"
            @click="goToLanding"
          >
        </div>
      </div>
      <div class="mt-2 mx-4 grid grid-cols-12 sm:grid sm:grid-cols-12">
        <!-- left markdown area -->
        <div class="col-span-2 mt-4"></div>
        <div class="col-span-8 mt-4" id="previewContainer">
          <div class="text-4xl font-bold">
            {{storeState.card.details.title}}
          </div>
          <div class="mt-8 h-full">
            <textarea class="hidden" name="" id="mdEditor" cols="30" rows="10"></textarea>  
          </div>
          <div class="mt-4">
            <!-- linked tags -->
            <span class="mr-2 inline-flex rounded cursor-pointer items-center py-2 px-3 text-sm font-medium bg-gray-800 hover:bg-gray-700 text-gray-100"
              v-for="(linked, index) in storeState.card.details.linked"
              :key="index"
              @click="goToCard(linked, $event)"
            >
              {{getCard(linked).details.title}}
            </span>
            <!-- linked search -->
            <div class="h-64 overflow-y-scroll" v-if="newLinked.adding">
              <div 
                v-for="(linkOption, linkedIndex) in storeState.cards.values.filter(c => filterLinkedOptions(c).slice(0, 10))"
                :key="linkedIndex"
                class="hover:bg-gray-800 rounded py-1 px-2 cursor-pointer"
                @click="addLinked(linkOption)"
              >
                <span class="truncate">
                  {{ linkOption.details.title ? linkOption.details.title : 'Untitled' }}
                </span>
              </div>
            </div>
            <!-- <div>
              {{storeState.card.details.fields}}
            </div> -->
          </div>
          
        </div>
        <div class="col-span-2 mt-4"></div>
        <!-- right side bar, status etc. -->
      </div>
    </div>
    <div 
      class="absolute bg-gray-800 text-gray-500 rounded"
      :class="{hidden: !(bracketCt === 2)}"
      :style="{ top: suggestion.top, left: suggestion.left }"
    >
      <div 
        v-for="(linkOption, linkedIndex) in storeState.cards.values.filter(c => filterLinkedOptionsEditor(c)).slice(0, 10)"
        :key="linkedIndex"
        class="hover:bg-gray-600 rounded py-2 pl-2 px-2 cursor-pointer"
        @click="addLinkedEditor(linkOption)"
      >
        <span class="truncate">
          {{ linkOption.details.title ? linkOption.details.title : 'Untitled' }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { store } from '../store.js';
import { reactive, ref } from 'vue'
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import axios from 'axios';
import SSF from 'ssf'
import Mousetrap from 'mousetrap'
import EasyMDE from "easymde";
import { Grid, html } from "gridjs";
import Popper from "vue3-popper";
import { supabase } from "../supabase"
import { debounce } from "debounce";
import CurrencyInput from '@/components/CurrencyInput.vue'

import { 
  UserIcon, 
  AnnotationIcon,
  HashtagIcon,
  TagIcon,
  EyeIcon,
  XIcon,
  ArchiveIcon,
  DocumentTextIcon,
  LightBulbIcon,
  OfficeBuildingIcon,
  CubeIcon,
  BookOpenIcon,
  SelectorIcon,
  CheckIcon,
  CheckCircleIcon,
  DuplicateIcon,
  InformationCircleIcon,
  PencilAltIcon,
  DotsHorizontalIcon,
  QuestionMarkCircleIcon,
  } from '@heroicons/vue/solid'

import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/vue";

export default {
  name: 'Metrics',
  props: {
      msg: String
  },
  components: {
  },
  created() {
    this.storeState.hideSidebar = true
    this.storeState.checkedLogin = true
    this.storeState.showLanding = false
    this.getTwitterUser = debounce(this.getTwitterUser, 200);
    this.updateCard = debounce(this.updateCard, 500);
    this.setupMousetrap()
    this.checkUrl();
    this.getPublicCards()
  },
  mounted() {
  },
  errorCaptured: function(err) {
    console.log('errorCaptured', err.message);
    return false;
  },
  data() {
      return {
        store: store,
        storeState: store.state,
        creatingCard: false,
        bracketCt: 0,
        suggestion: {
          top: null,
          left: null,
          overlayLeft: null,
          overlayTop: null,
        },
        selectingChannel: false,
        forceShowDescription: false,
        someCurrency: 0,
        showMenu: false,
        newLinked: {
          adding: false,
          search: '',
          public_uuid: '',
          details: {
            title: '',
            description: '',
          }
        },
      }
  },
  methods: {
    login() {
      this.$router.push({ name: 'Login', query: { goto: 'Landing' }})
    },
    logout() {
      this.$router.push({ name: 'Logout', query: { goto: 'Landing' }})
    },
    goToLanding() {
      this.storeState.showLanding = true
      this.$router.push({ name: 'Dashboard'})
    },
    async getPublicCards() {
      console.log('getPublicCards...')
      try {
        const result = await supabase.from('cards').select('*').is('is_archived', false).is('is_public', true).order('updated_on', { ascending: false })
        console.log('getCards', result)
        if (result.error) {
          console.log('error', result.error)
          return
        }
        
        // handle for when no todos are returned
        if (result.data === null) {
          console.log('no cards...')
          this.storeState.cards.ok = false
          return
        }
        
        // store response to allTodos
        this.storeState.cards.values = result.data
        var card = this.storeState.cards.values.find(c => c.public_uuid === this.$route.params.id)
        if (card) this.storeState.cards.ok = true
        console.log('got cards!', this.storeState.cards)
        this.checkUrl()
        } catch (err) {
          console.error('Error retrieving data from db', err)
        }
    },
    goalTypes(type) {
      let alreadyAddedIds = this.storeState.card.details.fields.map(f => f.type.id)
      if (alreadyAddedIds.includes(type.id)) return false
      return true
    },
    filterFieldTypes(type) {
      let alreadyAddedIds = this.storeState.card.details.fields.map(f => f.type.id)
      if (alreadyAddedIds.includes(type.id)) return false
      return true
    },
    removeField(index) {
      this.storeState.card.details.fields.splice(index, 1);
    },
    updateDueDate(d) {
      console.log('updateDueDate', d)
      this.storeState.card.details.dueDate = d.dueDate;
    },
    getEditableFields(obj) {
      let editable = ['goal', 'dueDate', 'twitter', 'priority']
      return Object.fromEntries(
        Object.entries(obj).filter(([key, value]) => editable.includes(key)) )
    },
    formatDueDate(date) {
      if (!date) return ''
      if (!date.toLocaleDateString) {
        date = new Date(date)
      }
      var options = { 
        weekday: 'short', 
        year: 'numeric', 
        month: 'short', 
        day: 'numeric' 
      };
      return date.toLocaleDateString("en-US", options)
    },
    openCmdk(cmd) {
      console.log('openCmdk', cmd)
    },
    formatFollowerCount(count) {
      if (count > 1000000) {
        return (count / 1000000).toFixed(1) + 'M'

      } else if (count > 1000) {
        return (count / 1000).toFixed(1) + 'K'
      
      } else {
        return count
      }
    },
    addField() {
      let firstType = this.storeState.cardMeta.fields[0]
      let field = {
        label: '',
        value: '',
        hovering: false,
        key: '',
        type: firstType,
      }
      this.storeState.card.details.fields.push(field)
    },
    mdTogglePreview() {
      console.log('mdTogglePreview...')
      let mdIsPreviewActive = window.easyMDE.isPreviewActive()
      let alreadyEditing = !mdIsPreviewActive
      if (this.storeState.card.details.editingMd && alreadyEditing) {
        window.easyMDE.togglePreview()
        this.storeState.card.details.editingMd = !this.storeState.card.details.editingMd //this.mdIsPreviewActive
        this.$forceUpdate()
      } else if (!this.storeState.card.details.editingMd && !alreadyEditing) {
        window.easyMDE.togglePreview()
        this.storeState.card.details.editingMd = !this.storeState.card.details.editingMd //this.mdIsPreviewActive
        this.$forceUpdate()
      } else if (this.storeState.card.details.editingMd && !alreadyEditing) {
        // window.easyMDE.togglePreview()
        this.storeState.card.details.editingMd = false
        this.$forceUpdate()
      } else if (!this.storeState.card.details.editingMd && alreadyEditing) {
        // window.easyMDE.togglePreview()
        this.storeState.card.details.editingMd = true
        this.$forceUpdate()
      }
      
    },
    filterLinkedOptions(linkOption) {
      let alreadyLinked = this.storeState.card.details.linked
      if (alreadyLinked.includes(linkOption.public_uuid)) return false
      if (linkOption.public_uuid === this.storeState.card.public_uuid) return false
      if (!linkOption.details.title) return false
      let st = this.newLinked.search.toLowerCase()
      let title = linkOption.details.title.toLowerCase()
      if (title.includes(st)) return true
      // let description = linkOption.details.description.toLowerCase()
      // if (description.includes(st)) return true
      return false
    },
    filterLinkedOptionsEditor(linkOption) {
      let alreadyLinked = this.storeState.card.details.linked
      if (alreadyLinked.includes(linkOption.public_uuid)) return false
      if (linkOption.public_uuid === this.storeState.card.public_uuid) return false
      if (!linkOption.details.title) return false
      let st = this.newLinked.search.toLowerCase()
      let title = linkOption.details.title.toLowerCase()
      if (title.includes(st)) return true
      // let description = linkOption.details.description.toLowerCase()
      // if (description.includes(st)) return true
      return false
    },
    removeLinked(cardId) {
      let arr = this.storeState.card.details.linked.filter(l => l != cardId)
      console.log('removeLinked: ', arr, cardId)
      this.storeState.card.details.linked = arr
    },
    addLinkedEditor(newLink) {
      console.log('addLinkedEditor: ', newLink)
      let alreadyAdded = this.storeState.card.details.linked.includes(newLink.public_uuid)
      if (!alreadyAdded) this.storeState.card.details.linked.push(newLink.public_uuid)
      let backlinked = newLink.details.linked.includes(this.storeState.card.public_uuid)
      if (!backlinked) {
        newLink.details.linked.push(this.storeState.card.public_uuid)
        this.updateCard(newLink)
      }
      let searchTerm = '[['
      this.addLinkToCm(searchTerm, newLink)
      // TODO add [Card Title](wax.run url) to md
    },
    // autoSelected, searchTerm, wordRange, beforeSearch, line)
    addLinkToCm(searchTerm, newLink) {
      this.bracketCt = 0
      let editor = window.easyMDE.codemirror
      let doc = editor.getDoc();
      var cur = editor.getCursor()
      let line = editor.getRange({line: cur.line, ch: 0}, {line: cur.line, ch: cur.ch})
      var wordRange = editor.findWordAt(cur);
      let code = editor.getValue()
      let title = newLink.details.title || 'Untitled'
      // let addThis = `[${title}](wax.run/card/${newLink.public_uuid})`
      let addThis = `[${title}](/card/${newLink.public_uuid})`
      doc.replaceRange(addThis, wordRange.anchor, wordRange.head);
    },
    addLinked(newLink) {
      console.log('addLinked: ', newLink)
      let alreadyAdded = this.storeState.card.details.linked.includes(newLink.public_uuid)
      if (!alreadyAdded) this.storeState.card.details.linked.push(newLink.public_uuid)
      let backlinked = newLink.details.linked.includes(this.storeState.card.public_uuid)
      if (!backlinked) {
        newLink.details.linked.push(this.storeState.card.public_uuid)
        this.updateCard(newLink)
      }
      this.newLinked.search = ''
    },
    onLinkSearchBlur() {
      setTimeout(() => this.newLinked.adding=false, 100);
    },
    setChannel(channel) {
      this.storeState.card.details.channel = channel.public_uuid
      this.addLinked(channel)
      this.selectingChannel = false
      this.creatingCard = false
      setTimeout(() => this.$refs.cardTitle.focus(), 1);
      setTimeout(() => this.createMdEditor(), 1);
      
    },
    setType(type) {
      this.storeState.card.details.type = type.id;
      if (type.id === 'strategy') {
        this.selectingChannel = true
      } else {
        this.selectingChannel = false
        this.creatingCard = false
        setTimeout(() => this.$refs.cardTitle.focus(), 1);
        setTimeout(() => this.createMdEditor(), 1);
      }
    },
    showField(field, card) {
      if (field === 'twitter') {
        let types = ['person', 'company']
        return types.includes(card.details.type)
      } else if (field === 'goal') {
        let types = ['goal']
        return types.includes(card.details.type)
      } else if (['dueDate', 'goal', 'status'].includes(field)) {
        let types = ['goal', 'task']
        return types.includes(card.details.type)
      } else if (field === 'priority') {
        let types = ['task', 'strategy']
        return types.includes(card.details.type)
      }
      return true
    },
    showTwitter(card) {
      let twitterTypes = ['person', 'company']
      return twitterTypes.includes(card.details.type)
    },
    getTwitterUrl() {
      let twitter = this.storeState.card.details.twitter
      if (!twitter) return false
      let url = `https://twitter.com/${twitter.replace('@', '')}`
      return url
    },
    async getTwitterUser(handle) {
      console.log('getting twitter handle: ', handle)
      if (!handle || handle.length < 1) {
        this.storeState.card.details.twitter.data = {}
        return false
      }
      // this.storeState.card.details.twitter = null
      // fetch("https://api.twitter.com/1.1/users/show.json?screen_name=twitterdev", {
      //   headers: {
      //     Authorization: "Bearer <API_TOKEN>"
      //   }
      // })
      const path = `https://www.wax.run/api`
      const d = {getTwitter: true, handle: handle}
      const res = await axios.post(path, d)
      console.log('got twitter: ', res.data)
      if (res.data.ok) {
        this.storeState.card.details.twitter.data = res.data.data
      } else {
        this.storeState.card.details.twitter.data = {}
      }
    },
    openCard() {
      if (this.storeState.card.details.type === 'person') {
        let twitter = this.storeState.card.details.twitter.replace('@', '')
        let url = `https://twitter.com/${twitter}`
        window.open(url, '_blank');
      }
    },
    setupMousetrap() {
      var self = this
      // Mousetrap.bind('4', function(e) { console.log('4'); });
      Mousetrap.bind('esc', function(e) { 
        if (e && e.preventDefault) e.preventDefault()
        self.$router.push({ name: 'Dashboard'})
      });
      Mousetrap.bind('command+enter', function(e) { 
        if (e && e.preventDefault) e.preventDefault()
        console.log('marktodo')
      });
      Mousetrap.bind('command+p', function(e) { 
        if (e && e.preventDefault) e.preventDefault()
        self.mdTogglePreview()
      });
    },
    getCard(cardId) {
      let c = this.storeState.cards.values.find(c => c.public_uuid === cardId)
      if (c) return c
      let noCard = store.deepCopy(this.storeState.cardTemplate) 
      noCard.details.title = 'Not found'
      return noCard
    },
    getCardType() {
      let t = this.storeState.cardMeta.types.find(t => t.id === this.storeState.card.details.type)
      if (t) return t
      return {}
    },
    checkUrl() {
      console.log('checkUrl0...', this.$route)
      if (this.$route.query.code) {
        console.log('checkUrl1...')
      }
      if (this.$route.params.id && this.storeState.cards.ok) {
        console.log('checkUrl2...')
        var card = this.storeState.cards.values.find(c => c.public_uuid === this.$route.params.id)
        if (card) {
          this.storeState.card = store.addMissingAttributes(card)
          setTimeout(() => this.createMdEditor(), 1);
        }
      }
    },
    removeElementsByClass(className){
      const elements = document.getElementsByClassName(className);
      while(elements.length > 0){
          elements[0].parentNode.removeChild(elements[0]);
      }
    },
    getCursorCoords(editor) {
      var coords = editor.cursorCoords(true);
      this.suggestion.left = coords.left + 'px'
      this.suggestion.top = coords.top + 25 + 'px'
      this.suggestion.overlayLeft = coords.left + 10 + 'px'
      this.suggestion.overlayTop = coords.top + 'px'
    },
    createMdEditor() {
      let mdEl = document.getElementById('mdEditor')
      if (!mdEl) return
      if (window.easyMDE) {
        console.log('easyMDE already exists, destroying...')
        window.easyMDE.toTextArea();
        window.easyMDE = null;
        this.removeElementsByClass('EasyMDEContainer')
      }
      var self = this
      window.easyMDE = new EasyMDE({
        element: mdEl,
        previewClass: ["editor-preview", "prose", "dark:prose-light", "prose-blue", "public-preview"],
        initialValue: this.storeState.card.details.md || '',
        placeholder: 'Notes (Markdown is supported)',
        showIcons: ['strikethrough'],
        shortcuts: {
          "strikethrough": "Cmd-Enter", 
        }
        // hideIcons: ['guide', 'heading', 'bold', 'italic', 'quote', 'bullets', 'unordered-list', 'ordered-list'],
      });
      window.easyMDE.value(this.storeState.card.details.md || '')
      window.easyMDE.togglePreview()
    },
    // duplicateCard() {
    copyCard(oldCard) {
      let newCard = store.deepCopy(oldCard)
      delete newCard.id
      newCard.public_uuid = store.uuidv4()
      newCard.details.title = `Copy of ${newCard.details.title}`
      newCard.details.status = this.storeState.cardMeta.taskStatuses.find(s => s.id === 'new')
      this.storeState.cards.values.unshift(newCard)
      this.$router.push({ name: 'Card', params: { id: newCard.public_uuid }})
    },
    archiveCard(card) {
      card.is_archived = true
      this.storeState.cards.values = this.storeState.cards.values.filter(c => card.public_uuid !== c.public_uuid)
      this.$router.push({ name: 'Dashboard'})
    },
    goToCard(cardId, e) {
      if (e.target && e.target.classList.contains('preventEvent')) {
        console.log('goToCard', e)
        return
      }
      this.$router.push({ name: 'Card', params: { id: cardId }})
      this.checkUrl()
    },
    timeSince(date) {
      var seconds = Math.floor((new Date() - date) / 1000);
      var interval = seconds / 31536000;
      if (interval > 1) {
        return Math.floor(interval) + " years";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " months";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " days";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " hours";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " minutes";
      }
      return Math.floor(seconds) + " seconds";
    },
    async updateCard(card) {
      delete card.hovering
      card = store.deepCopy(card)
      card.updated_on = new Date().toISOString();
      let ci = this.storeState.cards.values.findIndex((c => c.public_uuid == card.public_uuid));
      this.storeState.cards.values[ci] = card
      // card.updated_on = new Date().toISOString();
      const result = await supabase
        .from('cards')
        .update(card)
        .match({id: card.id})
      console.log('updated card...')
      if (result.error) {
        console.error('updateCard error', result.error)
        if (result.error.message === 'JWT expired') {
          this.$router.push({ name: 'Login' })
        }
        return
      }
    },
    ssfFormatter(format, val) {
      // return (m / 1000).toFixed(1)
      return SSF.format(format, val)
    },
  },
  computed: {
    // mdIsPreviewActive() {
    //   return window.easyMDE.isPreviewActive()
    // }
  },
  watch: {
    '$route.params.id': function (newId, oldId) {
      console.log('uuid changed', newId, oldId)
      this.checkUrl()
    },
  },
}
/* eslint-disable no-unused-vars */
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  #vg-tooltip-element.vg-tooltip.custom-theme {
    color: #f7fafc;
  }
  #vg-tooltip-element table tr td.key {
    color: #718096;
  }
  #vg-tooltip-element {
    background-color: #2d3748;
    color: #f7fafc;
    border: none;
  }
  .public-preview {
    background: red;
    display: contents !important;
  }
  #previewContainer .CodeMirror-scroll {
    display: none;
  }
  .editor-toolbar {
    background-color: #1a202c;
    color: #f7fafc;
    border: none;
    display: none;
  }
  .editor-toolbar.fullscreen {
    background-color: #1a202c;
    color: #f7fafc;
    border: none;
  }
  .EasyMDEContainer .CodeMirror-fullscreen {
    background-color: #1a202c;
    color: #f7fafc;
    border: none;
  }
  .EasyMDEContainer {
    height: 100% !important;
  }
  .EasyMDEContainer .CodeMirror {
    height: 100% !important;
  }
  .EasyMDEContainer .CodeMirror {
    height: 100% !important;
  }
  .cm-s-easymde {
    height: 100% !important;
  }
  .CodeMirror {
    height: 100% !important;
  }
  .editor-statusbar {
    @apply hidden;
  }
  .editor-preview {
    background-color: #1a202c;
    color: #f7fafc;
    border: none;
    padding: 0px !important;
  }
  .cm-s-easymde {
    background-color: #1a202c;
    color: #f7fafc;
    border: none !important;
    caret-color: #f7fafc !important;
    padding: 0px !important;
  }
  .CodeMirror-line {
    padding: 0px !important;
  }
  .CodeMirror-cursor {
    border-left: 1px solid #f7fafc !important;
  }
  .CodeMirror-placeholder {
    color: #718096 !important; 
  }
  .editor-toolbar button.active, .editor-toolbar button:hover {
    background-color: #718096 !important;
    border: none !important;
    @apply text-white border-0 border-transparent focus:border-transparent focus:ring-0;

  }
  button {
    outline: none !important;
  }
  focus:button {
    outline: none !important;
  }
  .prose > ul > li > *:last-child {
    margin-bottom: 0.2em !important;
  }
  .prose > ul > li > *:first-child {
    margin-top: 0.2em !important;
  }
  .prose-blue a {
    @apply hover:text-gray-200
  }
</style>
