<template>
  <div class="bg-gray-900 px-8 pt-16 pb-16 text-gray-100">
    <div v-if="storeState.loading">
      <div class="flex justify-center space-y-8 w-full pt-32">
        <svg class="animate-spin -ml-1 mr-3 h-20 w-20 text-gray-100" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </div>
    </div>
    <div v-else class="">      
      <div class="flex justify-between">
        <h1 class="text-6xl max-w-4xl font-black text-white text-center leading-none">
          {{ getGreeting() }}
        </h1>
      </div>
      <!-- UP NEXT / Onboarding flow -->
      <div class="mt-8">
        <div
          v-for="(step, index) in storeState.onboarding.steps"
          :key="index"
          
        >
          <div 
            v-if="index === storeState.onboarding.currentStep"
            @click="doOnboardStep(step)" 
            class="bg-gray-700 px-4 py-2 rounded-sm flex cursor-pointer text-gray-100 hover:text-gray-400"
          >
            <div class="flex-shrink-0">
              <ArrowCircleRightIcon style="margin-top: 3px;" class="h-6 w-6" aria-hidden="true" />
            </div>
            <div class="text-lg ml-2">
              {{step.todo}}
            </div>
          </div>
          <div 
            v-else
            @click="doOnboardStep(step)" 
            class="px-4 py-2 rounded-sm flex cursor-pointer"
          >
            <div class="flex-shrink-0">
              <CheckCircleIcon style="margin-top: 3px;" v-if="index < storeState.onboarding.currentStep" class="h-6 w-6 text-gray-600" aria-hidden="true" />
              <svg class="h-6 w-6 text-gray-600" v-else xmlns="http://www.w3.org/2000/svg" version="1.1">
                <circle class="h-6 w-6 text-gray-600" cx="12" cy="13" r="8" stroke="currentColor" stroke-width="3" fill="none" />
              </svg> 
            </div>
            <div class="text-lg ml-2 text-gray-600">
              {{step.todo}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import { store } from '../store.js';
import axios from 'axios';
import { supabase } from "../supabase"
import Mousetrap from 'mousetrap'
import SSF from 'ssf'

import { Grid, html } from "gridjs";
import MarkdownIt from 'markdown-it';
// import "gridjs/dist/theme/mermaid.css";

import { 
  CheckCircleIcon,
  ArrowCircleRightIcon,
  CurrencyDollarIcon,
  UserIcon,
  MapIcon,
  } from '@heroicons/vue/solid'

export default {
  name: 'Metrics',
  props: {
      msg: String
  },
  components: {
    CurrencyDollarIcon,
    ArrowCircleRightIcon,
    CheckCircleIcon,
    UserIcon,
    MapIcon,
    // Switch,
    // SwitchGroup,
    // SwitchLabel,
  },
  created() {
    this.setupMousetrap()
  },
  mounted() {
    
    this.emitter.on('user-login', d => {
      // this.getMetrics()
    });
  },
  data() {
      return {
        store: store,
        storeState: store.state,
        gridHover: null,
        searchTerm: '',
      }
  },
  methods: {
    login() {
      this.$router.push({ name: 'Login', query: { goto: 'Landing' }})
    },
    logout() {
      this.$router.push({ name: 'Logout', query: { goto: 'Landing' }})
    },
    getGreeting() {
      if (this.storeState.user && this.storeState.user.identities && this.storeState.user.identities.length > 0) {
        let identity = this.storeState.user.identities[0]
        if (identity.identity_data && identity.identity_data.full_name) {
          return `Welcome, ${identity.identity_data.full_name}`
        }
      }
      return 'Welcome'
    },
    async doOnboardStep(step) {
      console.log('step: ', step)
      if (step.id === 'connectSheets') {
        this.storeState.loading = true
        const path = `${window.location.origin}/api`
        const d = {
          step1: true, 
          type: 'google',
          user: this.storeState.user,
        }
        const res = await axios.post(path, d)
        console.log('doOnboardStep connectSheets', res.data)
        window.open(res.data.url, '_blank')
        this.storeState.loading = false
      } else if (step.id === 'launchAddon') {
        let url = `https://workspace.google.com/u/0/marketplace/app/wax/79190931278`
        window.open(url, '_blank')
      }
    },
    getTaskTimeSince(card) {
      if (card.details.dueDate) {
        var t = store.timeSince(new Date(card.details.dueDate))
        return t
      }
      return {ago: false}
    },
    getTimeAgo(t) {
      if (!t) return 'Just now'
      t = store.timeSince(new Date(t))
      t = store.timeSinceToStr(t)
      return t
    },
    goToTrending() {
      this.storeState.view = this.storeState.views.trending
      this.$router.push({ name: 'Views', params: { id: this.storeState.view.id }})
    },
    goToQueue() {
      this.storeState.view = this.storeState.views.queue
      this.$router.push({ name: 'Views', params: { id: this.storeState.view.id }})
    },
    goToChannels() {
      this.storeState.view = this.storeState.views.channel
      this.$router.push({ name: 'Views', params: { id: this.storeState.view.id }})
    },
    goToStrategies() {
      this.storeState.view = this.storeState.views.strategy
      this.$router.push({ name: 'Views', params: { id: this.storeState.view.id }})
    },
    getTaskDueStatus(card) {
      if (card.details.dueDate) {
        var t = store.timeSince(new Date(card.details.dueDate))
        t = store.timeSinceToStr(t)
        return `${t}`
      }
      return ''
    },
    getCardDescription(card) {
      return card.details.title || 'Untitled'
    },
    getCardStatus(card) {
      if (card.details.status && card.details.status.label) return card.details.status
      let s = this.storeState.cardMeta.taskStatuses.find(s => s.id === 'new')
      return s
    },
    filterTrending(c) {
      if (c.is_public) return true
    },
    filterCriticalPath(c) {
      if (c.details.type === 'criticalPath') return true
      return false
    },
    filterQueued(c) {
      if (c.details.type != 'tweet') return false
      if (c.details.tweet.status.id === 'in-queue') return true
      return false
      // if (c.details.status && ['new', 'in-progress'].includes(c.details.status.id)) return true
    },
    filterStrategies(c) {
      if (c.details.type != 'strategy') return false
      return true
      // if (c.details.status && ['new', 'in-progress'].includes(c.details.status.id)) return true
    },
    filterTasks(c) {
      if (c.details.type != 'task') return false
      if (c.details.status && ['new', 'in-progress'].includes(c.details.status.id)) return true
    },
    setupMousetrap() {
      var self = this
      // Mousetrap.bind('4', function(e) { console.log('4'); });
      Mousetrap.bind('esc', function(e) { 
        if (e && e.preventDefault) e.preventDefault()
        self.searchTerm = ''
        if (self.$refs.searchInput && self.$refs.searchInput.blur) self.$refs.searchInput.blur()
      });
      // Mousetrap.bind('/', function(e) { 
      //   if (e && e.preventDefault) e.preventDefault()
      //   if (self.$refs.searchInput && self.$refs.searchInput.focus) self.$refs.searchInput.focus()
      // });
    },
    addGoal() {

    },
    openCmdk(cmd) {
      console.log('openCmdk', cmd)
    },
    getGoalType(card) {
      if (!card.details.goal) return {}
      if (!card.details.goal.type) return {}
      return card.details.goal.type
    },
    getGoal(card) {
      if (!card.details.fields) return `${card.details.title}`
      let field = card.details.fields.find(f => f.type.goal)
      if (field && field.value) {
        // let val = SSF.format('#,##0', field.value)
        let val = store.formatCash(field.value)
        let dd = store.formatDateShort(card.details.dueDate)
        return `${field.type.label} to ${val} by ${dd}`
      }
      return `${card.details.title}`
    },
    ssfFormatter(format, val) {
      // return (m / 1000).toFixed(1)
      return SSF.format(format, val)
    },
    getCardType(card) {
      let t = this.storeState.cardMeta.types.find(t => t.id === card.details.type)
      if (t) return t
      return {}
    },
    async updateCard(card) {
      delete card.hovering
      let ci = this.storeState.cards.values.findIndex((c => c.public_uuid == card.public_uuid));
      this.storeState.cards.values[ci] = card
      const result = await supabase
        .from('cards')
        .update(card)
        .match({id: card.id})
      console.log('updateCard', result)
      if (result.error) {
        console.error('updateCard error', result.error)
        if (result.error.message === 'JWT expired') {
          this.$router.push({ name: 'Login' })
        }
        return
      }
    },
    createCriticalPath() {
      console.log('createCriticalPath...')
      this.storeState.card = store.deepCopy(this.storeState.cardTemplate)
      this.storeState.card.public_uuid = store.uuidv4()
      this.storeState.card.user_id = this.storeState.user.id
      this.storeState.card.details.type = 'criticalPath'
      let domain = this.storeState.user.email.split('@')[1]
      this.storeState.card.details.title = `Critical Path for ${domain}`
      let cpMd = `The steps to walking a mile are clear. Take one step, then about 1,999 more. `
      cpMd+= `The steps to achieve your traction goal are less clear, but still enumerable. `
      cpMd+= `Your **Critical Path** is the set of steps required to get to your goal. `
      cpMd+= `Not all steps need not be traction related, but they should be necessary to reach your goal. Keep your path high level, e.g. only list the 5 big steps needed.\n\n`
      cpMd+= `## What needs to get done to reach your goal?\n`
      cpMd+= `1. Step 1\n`
      cpMd+= `2. Step 2\n`
      cpMd+= `3. Step 3\n`
      cpMd+= `4. Step 4\n`
      cpMd+= `5. Step 5\n`
      this.storeState.card.details.md = cpMd
      this.storeState.cards.values.unshift(this.storeState.card)
      this.$router.push({ name: 'Card', params: { id: this.storeState.card.public_uuid }})
    },
    newCard() {
    },
    removeLinked(linkedId, card) {
      let arr = card.details.linked.filter(l => l != linkedId)
      card.details.linked = arr
      this.updateCard(card)
    },
    getDescription(card) {
      if (!card.details.md) return ''
      const md = new MarkdownIt();
      var result = md.renderInline(card.details.md);
      result = result.replace('# ', '').replace('## ', '').replace('### ', '')
      return result
    },
    getPeople(card) {
      if (card.details.people && card.details.people.length > 0) {
        const people = []
        for (let index = 0; index < card.details.people.length; index++) {
          const personId = card.details.people[index];
          const person = this.storeState.cards.values.find(c => c.public_uuid === personId)
          people.push(person)
        }
        return people
      }
    },
    goToCard(card, event) {
      console.log('goToCard: ', card)
      this.$router.push({ name: 'Card', params: { id: card.public_uuid }})
    },
    goToLinkedCard(cardId, event) {
      console.log('goToLinkedCard: ', cardId)
      this.$router.push({ name: 'Card', params: { id: cardId }})
    },
    goToPerson(person) {
      console.log('goToPerson: ', person)
      this.storeState.card = person
      this.$router.push({ name: 'Card', params: { id: person.public_uuid }})
    },
    getCard(cardId) {
      let c = this.storeState.cards.values.find(c => c.public_uuid === cardId)
      if (c) return c
      return this.storeState.cardTemplate
    },
    searchCard(st, card) {
      st = st.toLowerCase()
      if (!card.details) return 0
      let title = !card.details.title ? '' : card.details.title.toLowerCase()
      if (title.includes(st)) {
        return 100
      }
      let description = !card.details.description ? '' : card.details.description.toLowerCase()
      if (description.includes(st)) {
        return 100
      }
      let md = !card.details.md ? '' : card.details.md.toLowerCase()
      if (md.includes(st)) {
        return 100
      }
      if (!card.details.linked) return 0
      let linked = card.details.linked.find(l => this.getCard(l).details.title.toLowerCase().includes(st))
      if (linked) {
        return 100
      }
    },
  },
  watch: {
    'tableFilters': {
        handler: function () {
          // this.createCustomerTable()
        },
        deep: true
    },
    'searchTerm': {
        handler: function () {
          var st = this.searchTerm.toLowerCase()
        },
        deep: false
    },
  },
}
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  #vg-tooltip-element.vg-tooltip.custom-theme {
    color: #f7fafc;
  }
  #vg-tooltip-element table tr td.key {
    color: #718096;
  }
  #vg-tooltip-element {
    background-color: #2d3748;
    color: #f7fafc;
    border: none;
  }
  .description-lines {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
</style>
