<template>
  <div class="bg-gray-900 text-gray-100 pt-32 pb-8">
    <!-- Twitter  -->
    <!-- <div class="flex justify-center">
      <h3 class="text-2xl max-w-4xl font-black text-gray-500 uppercase text-center leading-none">
        Shitpost
      </h3>
    </div> -->
    <div class="pt-2 flex justify-center">
      <h1 class="text-6xl max-w-4xl font-black text-white text-center leading-none">
        Build apps and reports in <span class="bg-clip-text bg-gradient-to-b from-green-500 text-transparent to-green-400">Google Sheets.</span>
      </h1>
    </div>
    <div class="mt-5 flex justify-center">
      <!-- <div class="text-xl max-w-4xl font-regular text-gray-500 text-center">
        Feel like you're tweeting into a void? You are. 
        If you don't have thousands of followers almost no one will see your Tweets.
        How do you get real followers? 
        Wax alerts you as soon as someone in your niche Tweets so you can join the thread. 
        This is the fastest way for people interested in your niche to find you.
        It takes work, but Wax cuts out hundreds of hours of wasted time trying to grow in ways that don't work.
      </div> -->
      <div class="max-w-4xl font-regular text-center mt-3 text-xl text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
        Create a bidirectional link between Google Sheets and your database (Postgres, Snowflake, MySQL, Redshift, and SQL Server).
        Build powerful tools and automate work with Python, SQL & JS scripts.
      </div>
    </div>
    <div class="pt-16 flex justify-center">
      <button 
        @click="login"
        class="py-4 px-8 border border-transparent text-2xl font-semibold rounded-md text-white bg-green-400 shadow-sm hover:bg-green-500 focus:outline-none focus:shadow-outline-blue focus:bg-blue-500 active:bg-blue-600 transition duration-150 ease-in-out"
      >
        Get started
      </button>
    </div>
    <div class="pt-16 pb-16">
      <div class="flex justify-center">
        <a class="pointer-cursor" href="https://youtu.be/-A6u6ZfJsQk" target="_blank">
          <img style="max-width: 90%;" class="rounded" src="/syncGif33.gif"  alt="">
        </a>
      </div>
    </div>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div>
      <section class="py-12 bg-gray-900 overflow-hidden md:py-20 lg:py-24">
        <div class="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <svg class="absolute top-full right-full transform translate-x-1/3 -translate-y-1/4 lg:translate-x-1/2 xl:-translate-y-1/2" width="404" height="404" fill="none" viewBox="0 0 404 404" role="img" aria-labelledby="svg-workcation">
            <!-- <title id="svg-workcation">Workcation</title> -->
            <defs>
              <pattern id="ad119f34-7694-4c31-947f-5c9d249b21f3" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-700" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="404" fill="url(#ad119f34-7694-4c31-947f-5c9d249b21f3)" />
          </svg>

          <div class="relative">
            <blockquote class="mt-10">
              <div class="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-100">
                <p>
                  &ldquo;Woah, Frank really automated the sh*t out of that Sheet&rdquo;
                </p>
              </div>
              <footer class="mt-8">
                <div class="md:flex md:items-center md:justify-center">
                  <div class="md:flex-shrink-0">
                    <!-- <img class="mx-auto h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="" /> -->
                  </div>
                  <div class="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                    <div class="text-base font-medium text-gray-500">- Your coworker (if your name was Frank)</div>
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
      </section>
    </div>
    <div>
      <div class="mt-16 flex justify-center">
        <h2 class="text-6xl max-w-4xl font-black text-gray-100 text-center leading-none">
          <!-- Grow your Twitter, fast. -->
          How does it work?
        </h2>
      </div>
      <div class="pt-8 flex justify-center">
        <!-- <div class="text-xl max-w-4xl font-regular text-gray-500 text-center">
          Feel like you're tweeting into a void? You are. 
          If you don't have thousands of followers almost no one will see your Tweets.
          How do you get real followers? 
          Wax alerts you as soon as someone in your niche Tweets so you can join the thread. 
          This is the fastest way for people interested in your niche to find you.
          It takes work, but Wax cuts out hundreds of hours of wasted time trying to grow in ways that don't work.
        </div> -->
        <div class="text-xl max-w-4xl font-regular text-gray-300 text-center">
          Wax lets you write and <span class="font-bold"> execute SQL, Python & JS in Google Sheets</span>. 
          Simply launch the addon, connect to your database, and automate.
        </div>
      </div>
      <!-- <div class="mt-8 flex justify-center">
        <div class="flex justify-center">
          <img class="rounded" src="/waxLanding1.png"  alt="">
        </div>
      </div> -->
    </div>
    <!-- PYTHON -->
    <div class="pt-32">
      <div>
        <!-- This example requires Tailwind CSS v2.0+ -->
        <div class="relative bg-gray-900 pt-16 pb-32 overflow-hidden">
          <div class="mt-24">
            <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                <div>
                  <div>
                    <span class="h-12 w-12 rounded-md flex items-center justify-center bg-green-600">
                      <LightningBoltIcon class="h-6 w-6 text-white" aria-hidden="true" />
                    </span>
                  </div>
                  <div class="mt-6">
                    <h2 class="text-3xl font-extrabold tracking-tight text-gray-100">
                      Real-time, two-way sync
                    </h2>
                    <p class="mt-4 text-lg text-gray-500">
                      Wax is the only product that offers a true two-way sync between your database and Google Sheets. As soon as an 
                      edit is completed in Google Sheets, the changes are written to your database. Updates to your database 
                      are polled for and written to your Sheet.
                    </p>
                    <div class="mt-6">
                      <a href="/login" class="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700">
                        Get started
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                <div class="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                  <img 
                    class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none" 
                    src="/quickRunMySQL6.gif" 
                    alt="Sync GIF" 
                  />
                  <!-- <img 
                    class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none" 
                    src="/syncGif33.gif"  
                    alt="Sync GIF"
                  > -->
                </div>
              </div>
            </div>
          </div>
          <div class="relative mt-24">
            <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                <div>
                  <div>
                    <span class="h-12 w-12 rounded-md flex items-center justify-center bg-green-600">
                      <SparklesIcon class="h-6 w-6 text-white" aria-hidden="true" />
                    </span>
                  </div>
                  <div class="mt-6">
                    <h2 class="text-3xl font-extrabold tracking-tight text-gray-100">
                      Automate workflows where your team is already working
                    </h2>
                    <p class="mt-4 text-lg text-gray-500">
                      By building tools on top of Sheets, you're able to leverage all the existing access controls that Google offers 
                      with powerful automation delivered by Wax. Enable your team to securely call internal and external API's 
                      while managing all the code in one place.
                    </p>
                    <div class="mt-6">
                      <a @click="login" class="cursor-pointer inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700">
                        Get started
                      </a>
                    </div>
                  </div>
                </div>
                <div class="mt-8 border-t border-gray-200 pt-6">
                  <blockquote>
                    <div>
                      <p class="text-base text-gray-500">
                        &ldquo;You can have my spreadsheet after you ripped it from my cold, dead hands.&rdquo;
                      </p>
                    </div>
                    <footer class="mt-3">
                      <div class="flex items-center space-x-3">
                        <!-- <div class="flex-shrink-0">
                          <img class="h-6 w-6 rounded-full" src="" alt="" />
                        </div> -->
                        <div class="text-base font-medium text-gray-700">
                          - Your CFO
                        </div>
                      </div>
                    </footer>
                  </blockquote>
                </div>
              </div>
              <div class="mt-12 sm:mt-16 lg:mt-0">
                <div class="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                  <img 
                    class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none" 
                    src="/pythonGetJson3.gif"  
                    alt="Python automation GIF"
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="mt-24">
            <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                <div>
                  <div>
                    <span class="h-12 w-12 rounded-md flex items-center justify-center bg-green-600">
                      <ChartBarIcon class="h-6 w-6 text-white" aria-hidden="true" />
                    </span>
                  </div>
                  <div class="mt-6">
                    <h2 class="text-3xl font-extrabold tracking-tight text-gray-100">
                      Build operational reports and alerts
                    </h2>
                    <p class="mt-4 text-lg text-gray-500">
                      Easily connect to your database and get data flowing in and out of Google Sheets. 
                      Create dashboards and reports on the fly with SQL directly in your Sheet.
                      Get alerted on key events via Slack or email.
                    </p>
                    <div class="mt-6">
                      <a href="/login" class="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700">
                        Get started
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                <div class="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                  <img 
                    class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none" 
                    src="/waxLanding2.png" 
                    alt="Customer profile user interface" 
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END PYTHON -->
    <!-- pricing -->
    <div class="bg-gray-900 pt-16">
      <div class="pt-12 sm:pt-16 lg:pt-24">
        <div class="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
          <div class="max-w-3xl mx-auto space-y-2 lg:max-w-none">
            <h2 class="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">
              Pricing
            </h2>
            <p class="text-3xl font-extrabold text-white sm:text-4xl lg:text-5xl">
              One flat price for you and your entire team
            </p>
            <p class="mt-3 max-w-4xl mx-auto text-xl text-gray-300 sm:mt-5 sm:text-2xl">
              14 day free trial with full functionality
            </p>
          </div>
        </div>
      </div>
      <div class="mt-8 pb-12  sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
        <div class="relative">
          <div class="absolute inset-0 h-3/4 bg-gray-900" />
          <div class="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-3 lg:gap-5 lg:space-y-0">
              <div style="" v-for="tier in tiers" :key="tier.name" class="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div class="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                  <div>
                    <h3 class="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-green-100 text-green-600" id="tier-standard">
                      {{ tier.name }}
                    </h3>
                  </div>
                  <div class="mt-4 flex items-baseline text-6xl font-extrabold text-gray-900">
                    ${{ tier.priceMonthly }}
                    <span class="ml-1 text-2xl font-medium text-gray-500">
                      /mo
                    </span>
                  </div>
                  <p class="mt-5 text-lg text-gray-500">
                    {{ tier.description }}
                  </p>
                </div>
                <div class="flex-1 flex flex-col  px-6 pt-6 pb-8 bg-gray-50 space-y-6 sm:p-10 sm:pt-6">
                  <ul role="list" class="space-y-4">
                    <li v-for="feature in tier.features" :key="feature" class="flex items-start">
                      <div class="flex-shrink-0">
                        <CheckIcon class="h-6 w-6 text-green-500" aria-hidden="true" />
                      </div>
                      <p class="ml-3 text-base text-gray-700">
                        {{ feature }}
                      </p>
                    </li>
                  </ul>
                  <div class="rounded-md shadow">
                    <a style="" :href="tier.href" class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900" aria-describedby="tier-standard">
                      Start free trial
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4 relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:mt-5">
          <div class="max-w-md mx-auto lg:max-w-5xl">
            <div class="rounded-lg bg-gray-100 px-6 py-8 sm:p-10 lg:flex lg:items-center">
              <div class="flex-1">
                <div>
                  <h3 class="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-green-100 text-green-600" id="tier-standard">
                    Team
                  </h3>
                </div>
                <div class="mt-4 text-lg text-gray-600 px-4">Get unlimited runs to support your entire team for <span class="font-semibold text-gray-900">$500 /mo</span>.</div>
              </div>
              <div class="rounded-md shadow">
                <a style="" href="/login" class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900" aria-describedby="tier-standard">
                  Get started
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-16 mb-16 flex justify-center gap-8">
      <a href="mailto:hello@wax.run" target="_blank" class="cursor-pointer hover:text-gray-100 text-gray-300">
        Support
      </a>
      <a href="/terms" target="_blank" class="cursor-pointer hover:text-gray-100 text-gray-300">
        Privacy
      </a>
      <a href="https://twitter.com/waxdotrun" target="_blank" class="cursor-pointer hover:text-gray-100 text-gray-300">
        Twitter
      </a>
    </div>
    
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import { store } from '../store.js';
import axios from 'axios';
import { supabase } from "../supabase"
import Mousetrap from 'mousetrap'
import SSF from 'ssf'

import { Grid, html } from "gridjs";
import MarkdownIt from 'markdown-it';
// import "gridjs/dist/theme/mermaid.css";

import { 
  CheckCircleIcon,
  CheckIcon,
  ArrowCircleRightIcon,
  CurrencyDollarIcon,
  // LocationMarkerIcon,
  // MailIcon,
  UserIcon,
  MapIcon,
  } from '@heroicons/vue/solid'

import { 
  MailIcon,
  LocationMarkerIcon,
  CalendarIcon,
  InboxIcon, 
  ChartBarIcon,
  LightningBoltIcon,
  SparklesIcon,
  } from '@heroicons/vue/outline'

export default {
  name: 'Landing',
  props: {
      msg: String
  },
  components: {
    CheckIcon,
    LightningBoltIcon,
    CurrencyDollarIcon,
    ArrowCircleRightIcon,
    LocationMarkerIcon,
    CalendarIcon,
    CheckCircleIcon,
    UserIcon,
    MapIcon,
    MailIcon,
    InboxIcon,
    ChartBarIcon,
    SparklesIcon,
    // Switch,
    // SwitchGroup,
    // SwitchLabel,
  },
  created() {
    this.storeState.hideSidebar = true
    this.setupMousetrap()
  },
  mounted() {
    this.updateFollowers()
    this.emitter.on('user-login', d => {
      // this.getMetrics()
    });
  },
  data() {
      return {
        store: store,
        storeState: store.state,
        tiers: [
          {
            name: 'Free',
            href: '/login',
            priceMonthly: 0,
            description: 'Create SQL reports in Sheets',
            features: [
              'Write SQL in Sheets',
              '1,000 runs per month',
              'Connect to Postgres + MySQL',
            ],
          },
          {
            name: 'Standard',
            href: '/login',
            priceMonthly: 100,
            description: 'Automate with SQL in Sheets',
            features: [
              'Write SQL in Sheets',
              '10,000 runs per month',
              'Connect to Postgres + MySQL',
            ],
          },
          {
            name: 'Premium',
            href: '/login',
            priceMonthly: 200,
            description: 'Build powerful apps in Sheets',
            features: [
              'Two-way SQL to Sheets sync',
              '20,000 runs per month',
              'Snowflake, SQL Server, Redshift, Python support',
            ],
          },
        ],
        gridHover: null,
        searchTerm: '',
        followers: '73.8K',
        followerIndex: 0,
      }
  },
  methods: {
    login() {
      this.$router.push({ name: 'Login', query: { goto: 'Dashboard' }})
    },
    logout() {
      this.$router.push({ name: 'Logout', query: { goto: 'Landing' }})
    },
    updateFollowers() {
      let followerCounts = ['100', '931', '1,821', '3,902', '8,455', '12.2K', '28.4K', '72.8K']
      var self = this
      let fctInterval = setInterval(function() {
          self.followerIndex+=1
          if (self.followerIndex > followerCounts.length-1) self.followerIndex = 0
          self.followers = followerCounts[self.followerIndex]
      }, 2500);
    },
    doOnboardStep(step) {
      console.log('step: ', step)
      if (step.id === 'goal') {
        this.openCmdk({id: 6309})
      } else if (step.id === 'path') {
        this.createCriticalPath()
      } else if (step.id === 'channels') {
        this.goToChannels()
      } else if (step.id === 'strategies') {
        this.openCmdk({id: 6749})
      }
    },
    getTaskTimeSince(card) {
      if (card.details.dueDate) {
        var t = store.timeSince(new Date(card.details.dueDate))
        return t
      }
      return {ago: false}
    },
    getTimeAgo(t) {
      if (!t) return 'Just now'
      t = store.timeSince(new Date(t))
      t = store.timeSinceToStr(t)
      return t
    },
    goToTrending() {
      this.storeState.view = this.storeState.views.trending
      this.$router.push({ name: 'Views', params: { id: this.storeState.view.id }})
    },
    goToChannels() {
      this.storeState.view = this.storeState.views.channel
      this.$router.push({ name: 'Views', params: { id: this.storeState.view.id }})
    },
    goToStrategies() {
      this.storeState.view = this.storeState.views.strategy
      this.$router.push({ name: 'Views', params: { id: this.storeState.view.id }})
    },
    getTaskDueStatus(card) {
      if (card.details.dueDate) {
        var t = store.timeSince(new Date(card.details.dueDate))
        t = store.timeSinceToStr(t)
        return `${t}`
      }
      return ''
    },
    getCardDescription(card) {
      return card.details.title || 'Untitled'
    },
    getCardStatus(card) {
      if (card.details.status && card.details.status.label) return card.details.status
      let s = this.storeState.cardMeta.taskStatuses.find(s => s.id === 'new')
      return s
    },
    filterTrending(c) {
      if (c.is_public) return true
    },
    filterCriticalPath(c) {
      if (c.details.type === 'criticalPath') return true
      return false
    },
    filterStrategies(c) {
      if (c.details.type != 'strategy') return false
      return true
      // if (c.details.status && ['new', 'in-progress'].includes(c.details.status.id)) return true
    },
    filterTasks(c) {
      if (c.details.type != 'task') return false
      if (c.details.status && ['new', 'in-progress'].includes(c.details.status.id)) return true
    },
    setupMousetrap() {
      var self = this
      // Mousetrap.bind('4', function(e) { console.log('4'); });
      Mousetrap.bind('esc', function(e) { 
        if (e && e.preventDefault) e.preventDefault()
        self.searchTerm = ''
        if (self.$refs.searchInput && self.$refs.searchInput.blur) self.$refs.searchInput.blur()
      });
      // Mousetrap.bind('/', function(e) { 
      //   if (e && e.preventDefault) e.preventDefault()
      //   if (self.$refs.searchInput && self.$refs.searchInput.focus) self.$refs.searchInput.focus()
      // });
    },
    addGoal() {

    },
    openCmdk(cmd) {
      console.log('openCmdk', cmd)
    },
    getGoalType(card) {
      if (!card.details.goal) return {}
      if (!card.details.goal.type) return {}
      return card.details.goal.type
    },
    getGoal(card) {
      if (!card.details.fields) return `${card.details.title}`
      let field = card.details.fields.find(f => f.type.goal)
      if (field && field.value) {
        // let val = SSF.format('#,##0', field.value)
        let val = store.formatCash(field.value)
        let dd = store.formatDateShort(card.details.dueDate)
        return `${field.type.label} to ${val} by ${dd}`
      }
      return `${card.details.title}`
    },
    ssfFormatter(format, val) {
      // return (m / 1000).toFixed(1)
      return SSF.format(format, val)
    },
    getCardType(card) {
      let t = this.storeState.cardMeta.types.find(t => t.id === card.details.type)
      if (t) return t
      return {}
    },
    async updateCard(card) {
      delete card.hovering
      let ci = this.storeState.cards.values.findIndex((c => c.public_uuid == card.public_uuid));
      this.storeState.cards.values[ci] = card
      const result = await supabase
        .from('cards')
        .update(card)
        .match({id: card.id})
      console.log('updateCard', result)
      if (result.error) {
        console.error('updateCard error', result.error)
        if (result.error.message === 'JWT expired') {
          this.$router.push({ name: 'Login' })
        }
        return
      }
    },
    createCriticalPath() {
      console.log('createCriticalPath...')
      this.storeState.card = store.deepCopy(this.storeState.cardTemplate)
      this.storeState.card.public_uuid = store.uuidv4()
      this.storeState.card.user_id = this.storeState.user.id
      this.storeState.card.details.type = 'criticalPath'
      let domain = this.storeState.user.email.split('@')[1]
      this.storeState.card.details.title = `Critical Path for ${domain}`
      let cpMd = `The steps to walking a mile are clear. Take one step, then about 1,999 more. `
      cpMd+= `The steps to achieve your traction goal are less clear, but still enumerable. `
      cpMd+= `Your **Critical Path** is the set of steps required to get to your goal. `
      cpMd+= `Not all steps need not be traction related, but they should be necessary to reach your goal. Keep your path high level, e.g. only list the 5 big steps needed.\n\n`
      cpMd+= `## What needs to get done to reach your goal?\n`
      cpMd+= `1. Step 1\n`
      cpMd+= `2. Step 2\n`
      cpMd+= `3. Step 3\n`
      cpMd+= `4. Step 4\n`
      cpMd+= `5. Step 5\n`
      this.storeState.card.details.md = cpMd
      this.storeState.cards.values.unshift(this.storeState.card)
      this.$router.push({ name: 'Card', params: { id: this.storeState.card.public_uuid }})
    },
    newCard() {
      // let cardId = store.uuidv4()
      // this.$router.push({ name: 'Card', params: { id: cardId }})
      // this.openCmdk(6322)
    },
    removeLinked(linkedId, card) {
      let arr = card.details.linked.filter(l => l != linkedId)
      card.details.linked = arr
      this.updateCard(card)
    },
    getDescription(card) {
      if (!card.details.md) return ''
      const md = new MarkdownIt();
      var result = md.renderInline(card.details.md);
      result = result.replace('# ', '').replace('## ', '').replace('### ', '')
      return result
    },
    getPeople(card) {
      if (card.details.people && card.details.people.length > 0) {
        const people = []
        for (let index = 0; index < card.details.people.length; index++) {
          const personId = card.details.people[index];
          const person = this.storeState.cards.values.find(c => c.public_uuid === personId)
          people.push(person)
        }
        return people
      }
    },
    goToCard(card, event) {
      console.log('goToCard: ', card)
      this.$router.push({ name: 'Card', params: { id: card.public_uuid }})
    },
    goToLinkedCard(cardId, event) {
      console.log('goToLinkedCard: ', cardId)
      this.$router.push({ name: 'Card', params: { id: cardId }})
    },
    goToPerson(person) {
      console.log('goToPerson: ', person)
      this.storeState.card = person
      this.$router.push({ name: 'Card', params: { id: person.public_uuid }})
    },
    getCard(cardId) {
      let c = this.storeState.cards.values.find(c => c.public_uuid === cardId)
      if (c) return c
      return this.storeState.cardTemplate
    },
    searchCard(st, card) {
      st = st.toLowerCase()
      if (!card.details) return 0
      let title = !card.details.title ? '' : card.details.title.toLowerCase()
      if (title.includes(st)) {
        return 100
      }
      let description = !card.details.description ? '' : card.details.description.toLowerCase()
      if (description.includes(st)) {
        return 100
      }
      let md = !card.details.md ? '' : card.details.md.toLowerCase()
      if (md.includes(st)) {
        return 100
      }
      if (!card.details.linked) return 0
      let linked = card.details.linked.find(l => this.getCard(l).details.title.toLowerCase().includes(st))
      if (linked) {
        return 100
      }
    },
  },
  watch: {
    'tableFilters': {
        handler: function () {
          // this.createCustomerTable()
        },
        deep: true
    },
    'searchTerm': {
        handler: function () {
          var st = this.searchTerm.toLowerCase()
        },
        deep: false
    },
  },
}
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  #vg-tooltip-element.vg-tooltip.custom-theme {
    color: #f7fafc;
  }
  #vg-tooltip-element table tr td.key {
    color: #718096;
  }
  #vg-tooltip-element {
    background-color: #2d3748;
    color: #f7fafc;
    border: none;
  }
  .description-lines {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .thinIcon path {
    stroke-width: 1 !important;
  }
  .pin {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-size: 15px;
    pointer-events: auto;
    cursor: pointer;
    -webkit-box-direction: normal;
    display: inline-block;
    fill: currentcolor;
    max-width: 100%;
    position: relative;
    vertical-align: text-bottom;
    user-select: none;
    color: #718096;
    height: 16px;
    width: 16px;
    margin-top: 2px;
  }
  .reply {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    pointer-events: auto;
    cursor: pointer;
    font: 14px -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
    overflow-wrap: break-word;
    line-height: 20px;
    font-family: TwitterChirp, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: 400;
    white-space: nowrap;
    color: #718096;
    -webkit-box-direction: normal;
    display: inline-block;
    fill: currentcolor;
    height: 1.25em;
    max-width: 100%;
    position: relative;
    vertical-align: text-bottom;
    user-select: none;
    width: 1.25em;
  }
  .retweet {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    pointer-events: auto;
    cursor: pointer;
    font: 14px -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif;
    overflow-wrap: break-word;
    line-height: 20px;
    font-family: TwitterChirp, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
    font-size: 15px;
    font-weight: 400;
    white-space: nowrap;
    color: #718096;
    -webkit-box-direction: normal;
    display: inline-block;
    fill: currentcolor;
    height: 1.25em;
    max-width: 100%;
    position: relative;
    vertical-align: text-bottom;
    user-select: none;
    width: 1.25em;
  }
</style>
