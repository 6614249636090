import {createRouter, createWebHistory} from 'vue-router'
import Landing from '../views/Landing.vue'
import Dashboard from '../views/Dashboard.vue'
import Card from '../views/Card.vue'
import Views from '../views/Views.vue'
import Public from '../views/Public.vue'
import Settings from '../views/Settings.vue'
import Login from '../views/Login.vue'
import Callback from '../views/Callback.vue'
import Logout from '../views/Logout.vue'
import Terms from '../views/Terms.vue'

// Vue.use(VueRouter)

const routerHistory = createWebHistory()

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/google2',
    name: 'Google2',
    component: Callback
  },
  {
    path: '/terms',
    name: 'terms',
    component: Terms
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: Terms
  },
  {
    path: '/card/:id',
    name: 'Card',
    props: true,
    component: Card
  },
  {
    path: '/public/:id',
    name: 'Public',
    props: true,
    component: Public
  },
  {
    path: '/views/:id',
    name: 'Views',
    props: true,
    component: Views
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
  {
    path: '/team',
    name: 'Team',
    component: Settings
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/callback',
    name: 'Callback',
    component: Callback
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout
  },
]

const router = new createRouter({
  mode: 'history',
  history: routerHistory,
  base: process.env.BASE_URL,
  routes: routes
})

export default router
