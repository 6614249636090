<template>
  <div class="bg-gray-900 mx-8 mt-4 text-gray-100">
    <div v-if="!storeState.cards.ok">
      <div class="flex justify-center space-y-8 w-full pt-32">
        <svg class="animate-spin -ml-1 mr-3 h-20 w-20 text-gray-100" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path 
            class="opacity-75" 
            fill="currentColor" 
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
          </path>
        </svg>
      </div>
    </div>
    <div v-else-if="selectingChannel">
      <div 
        class="grid grid-cols-12 sm:grid sm:grid-cols-12"
        v-for="(card, index) in storeState.cards.values.filter(c => c.details.type === 'channel')"
        :key="index"
        @mouseenter="card.hovering=true"
        @mouseleave="card.hovering=false"
      >
        <div class="col-span-1"></div>
        <div 
          class="flex py-4 px-3 rounded col-span-10 border my-2 hover:bg-gray-700 cursor-pointer"
          @click="setChannel(card)"
          @mouseenter="card.hovering=true"
          @mouseleave="card.hovering=false"
        >
          <!-- <component 
            :is="type.icon" 
            class="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500 flex-shrink-0" 
            aria-hidden="true" 
          /> -->
          <div class="text-gray-100">
            {{ card.details.title }}
          </div>
        </div>
        <div class="col-span-1"></div>
      </div>
    </div>
    <div v-else-if="creatingCard">
      <div 
        class="grid grid-cols-12 sm:grid sm:grid-cols-12 sm:gap-4"
        v-for="(type, index) in this.storeState.cardMeta.types"
        :key="index"
      >
        <div class="col-span-1"></div>
        <div 
          class="flex py-4 px-3 rounded col-span-10 border my-2 hover:bg-gray-700 cursor-pointer"
          @click="setType(type)"
          @mouseenter="type.hovering=true"
          @mouseleave="type.hovering=false"
        >
          <component 
            :is="type.icon" 
            class="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500 flex-shrink-0" 
            aria-hidden="true" 
          />
          <div class="text-gray-100">
            {{ type.name }}
            <div 
              v-html="type.description" 
              :class="{
                'text-gray-100': type.hovering,
                'text-gray-700': !type.hovering,
                }"
            >
            </div>
          </div>
        </div>
        <div class="col-span-1"></div>
      </div>
    </div>
    <div ref="mainContainer" v-else class="">
      <div class="flex mx-4">
        <!-- <div>
          <Popper class="inline" hover placement="left">
            <component 
              :is="getCardType().icon" 
              class="mr-2 mt-1 h-10 w-10 text-gray-400 group-hover:text-gray-500 flex-shrink-0" 
              aria-hidden="true" 
            />
            <template #content>
              <div class="bg-gray-700 text-gray-100 ml-4 px-3 py-2 rounded">{{getCardType().name}}</div>
            </template>
          </Popper>
        </div> -->
        <input 
          v-model="storeState.card.details.title" 
          class="mousetrap flex-grow truncate p-0 ml-0 bg-gray-900 outline-none border-0 border-transparent focus:border-transparent focus:ring-0 text-4xl font-bold placeholder-gray-700 w-2/3"
          type="text"
          placeholder="Title"
          ref="cardTitle"
        >
        <div class="flex flex-1 justify-end">
          
          <div
            @mouseenter="showMenu=true"
            @mouseleave="showMenu=false"
            class="z-10 mt-3"
          >
            <DotsHorizontalIcon class="mb-1 inline h-5 w-5 text-gray-500 cursor-pointer" aria-hidden="true" />
            <div 
              class="absolute bg-gray-800 mr-8 -mt-8 right-0 w-56 rounded-sm" 
              v-if="showMenu"
            >
              <div v-if="storeState.card.details.editingMd" @click="mdTogglePreview" class="pr-4 pl-4 py-3 hover:bg-gray-700 cursor-pointer">
                <Popper class="inline" hover placement="left">
                  <div class="inline-block">
                    <PencilAltIcon class="mb-1 inline h-5 w-5 text-gray-500 mr-2" aria-hidden="true" />
                    Edit
                  </div>
                  <template #content>
                    <div class="bg-gray-700 text-gray-100 mr-4 px-3 py-2 rounded w-56">
                      Edit markdown 
                      <span class="ml-2">
                        <kbd class="bg-gray-600 py-1 px-2 rounded">⌘</kbd> + 
                        <kbd class="bg-gray-600 py-1 px-2 rounded">P</kbd>
                      </span>
                    </div>
                    <!-- <span class="sr-only"></span> -->
                  </template>
                </Popper>
              </div>
              <div v-else @click="mdTogglePreview" class="pr-4 pl-4 py-3 hover:bg-gray-700 cursor-pointer">
                <Popper class="inline" hover placement="left">
                  <div class="inline-block">
                    <DocumentTextIcon class="mb-1 inline h-5 w-5 text-gray-500 mr-2" aria-hidden="true" />
                    Preview
                  </div>
                  <template #content>
                    <div class="bg-gray-700 text-gray-100 mr-4 px-3 py-2 rounded w-56">
                      Show published 
                      <span class="ml-2">
                        <kbd class="bg-gray-600 py-1 px-2 rounded">⌘</kbd> + 
                        <kbd class="bg-gray-600 py-1 px-2 rounded">P</kbd>
                      </span>
                    </div>
                    <!-- <span class="sr-only"></span> -->
                  </template>
                </Popper>
              </div>
              <div @click="copyCard(storeState.card)" class="pr-4 pl-4 py-3 hover:bg-gray-700 cursor-pointer">
                <DuplicateIcon class="mb-1 inline h-5 w-5 text-gray-500 mr-2" aria-hidden="true" />
                Duplicate
              </div>
              <!-- <div @click="forceShowDescription=true" class="pr-4 pl-4 py-3 hover:bg-gray-700 cursor-pointer">
                <InformationCircleIcon class="mb-1 inline h-5 w-5 text-gray-500 mr-2" aria-hidden="true" />
                Add description
              </div> -->
              <div @click="archiveCard(storeState.card)" class="flex justify-between px-4 py-3 hover:bg-gray-700 cursor-pointer">
                <Popper class="inline" hover placement="left">
                  <div class="inline-block">
                    <ArchiveIcon class="mb-1 inline h-5 w-5 text-gray-500 mr-2" aria-hidden="true" />
                    Archive
                  </div>
                  <template #content>
                    <div class="bg-gray-700 text-gray-100 mr-4 px-3 py-2 rounded">Archive this card</div>
                    <!-- <span class="sr-only"></span> -->
                  </template>
                </Popper>
              </div>
              <div class="flex justify-between px-4 py-3 hover:bg-gray-700">
                <Popper class="inline" hover placement="left">
                  <div class="inline-block">
                    <EyeIcon class="mb-1 inline h-5 w-5 text-gray-500 mr-2" aria-hidden="true" />
                    Public
                  </div>
                  <template #content>
                    <div class="bg-gray-700 text-gray-100 mr-4 px-3 py-2 rounded">Make the card public to the internet</div>
                    <!-- <span class="sr-only"></span> -->
                  </template>
                </Popper>
                <Switch v-model="storeState.card.is_public" :class="[storeState.card.is_public ? 'bg-blue-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500']">
                  <span :class="[storeState.card.is_public ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']">
                    <span :class="[storeState.card.is_public ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                      <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                        <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                    </span>
                    <span :class="[storeState.card.is_public ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100', 'absolute inset-0 h-full w-full flex items-center justify-center transition-opacity']" aria-hidden="true">
                      <svg class="h-3 w-3 text-blue-600" fill="currentColor" viewBox="0 0 12 12">
                        <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                      </svg>
                    </span>
                  </span>
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-2 mx-4 grid grid-cols-12 sm:grid sm:grid-cols-12">
        <!-- left markdown area -->
        <div class="col-span-7 mt-4 mr-8" id="mdContainer">
          <textarea class="hidden" name="" id="mdEditor" cols="30" rows="10"></textarea>
        </div>
        <!-- right side bar, status etc. -->
        <div class="col-span-5 mt-0 border-l border-gray-700">
          <!-- CARD TYPE -->
          <div class="grid grid-cols-12 sm:grid sm:grid-cols-12 gap-8 mt-4 ml-4">
            <div class="col-span-4 sm:col-span-4">
              <div class="bg-gray-900 text-gray-600 font-medium relative w-full border border-gray-900 rounded-md pl-3 py-2 text-left">
                Type
              </div>
            </div>
            <div class="col-span-8">
              <div 
                @click="openCmdk({id: 6307})" 
                class="bg-gray-800 py-3 text-gray-400 hover:text-gray-100 w-full rounded-md shadow-sm cursor-pointer flex items-center" 
                v-if="true"
              >
                <div class="ml-3 flex" v-if="storeState.card.details.type">
                  <component 
                    :is="getCardType().icon" 
                    class="mr-2 mt-1 h-4 w-4 text-gray-400 group-hover:text-gray-500 flex-shrink-0" 
                    aria-hidden="true" 
                  />
                  <!-- {{storeState.card.details.type}} -->
                  {{getCardType().name}}
                </div>
                <div class="ml-3" v-else>
                  Select type
                </div>
              </div>
            </div>
          </div>
          <!-- STATUS -->
          <div
            v-if="showField('status', storeState.card)" 
            class="grid grid-cols-12 sm:grid sm:grid-cols-12 gap-8 mt-4 ml-4"
          >
            <div class="col-span-4 sm:col-span-4">
              <div class="bg-gray-900 text-gray-600 font-medium relative w-full border border-gray-900 rounded-md pl-3 py-2 text-left">
                Status
              </div>
            </div>
            <div class="col-span-8">
              <div 
                @click="openCmdk({id: 6292})" 
                class="bg-gray-800 py-3 text-gray-400 hover:text-gray-100 w-full rounded-md shadow-sm cursor-pointer flex items-center" 
                v-if="true"
              >
                <div class="ml-3 flex" v-if="storeState.card.details.status && storeState.card.details.status.label">
                  <component 
                    :is="getStatusIcon(storeState.card.details.status)" 
                    class="mr-2 mt-1 h-4 w-4 text-gray-400 group-hover:text-gray-500 flex-shrink-0" 
                    aria-hidden="true" 
                  />
                  {{storeState.card.details.status.label}}
                </div>
                <div class="ml-3" v-else>
                  Select status
                </div>
              </div>
            </div>
          </div>
          <!-- PRIORITY -->
          <div 
            v-if="showField('priority', storeState.card)" 
            class="grid grid-cols-12 sm:grid sm:grid-cols-12 gap-8 mt-4 ml-4"
          >
            <div class="col-span-4 sm:col-span-4">
              <div class="bg-gray-900 text-gray-600 font-medium relative w-full border border-gray-900 rounded-md pl-3 py-2 text-left">
                Priority
              </div>
            </div>
            <div class="col-span-8">
              <div 
                @click="openCmdk({id: 6203})" 
                class="bg-gray-800 py-3 text-gray-400 hover:text-gray-100 w-full rounded-md shadow-sm cursor-pointer flex items-center" 
              >
                <div class="ml-3" v-if="storeState.card.details.priority && storeState.card.details.priority.label">
                  {{storeState.card.details.priority.label}}
                </div>
                <div class="ml-3" v-else>
                  Select priority
                </div>
              </div>
            </div>
          </div>
          <!-- DUE DATE -->
          <div 
            v-if="showField('dueDate', storeState.card)" 
            class="grid grid-cols-12 sm:grid sm:grid-cols-12 gap-8 mt-4 ml-4"
          >
            <div class="col-span-4 sm:col-span-4">
              <div class="bg-gray-900 text-gray-600 font-medium relative w-full border border-gray-900 rounded-md pl-3 py-2 text-left">
                Due Date
              </div>
            </div>
            <div class="col-span-8">
              <div
                @click="openCmdk({id: 6200})"
                class="bg-gray-800 py-3 text-gray-400 hover:text-gray-100 w-full rounded-md shadow-sm cursor-pointer flex items-center" 
              >
                <div class="ml-3" v-if="storeState.card.details.dueDate">
                  {{formatDueDate(storeState.card.details.dueDate)}}
                </div>
                <div class="ml-3" v-else>
                  Select date
                </div>
              </div>
            </div>
          </div>
          <!-- GOALS -->
          <div v-if="showField('goal', storeState.card)" class="grid grid-cols-12 sm:grid sm:grid-cols-12 gap-8 mt-4 ml-4">
            <div class="col-span-4 sm:col-span-4">
              <div class="bg-gray-900 text-gray-600 font-medium relative w-full border border-gray-900 rounded-md pl-3 py-2 text-left">
                <div 
                  v-if="storeState.card.details.goal.type && storeState.card.details.goal.type.label"
                >
                  {{storeState.card.details.goal.type.label}}
                </div>
                <div v-else>
                  Goal
                </div>
              </div>
            </div>
            <div class="col-span-8">
              <div 
                class="flex-1" 
                v-if="storeState.card.details.goal.type && storeState.card.details.goal.type.format === 'currency'"
              >
                <div class="relative rounded-md shadow-sm">
                  <currency-input
                    v-model="storeState.card.details.goal.value" 
                    :options="{ currency: 'USD', precision: 0, currencyDisplay: 'hidden' }"
                    class="block w-full border text-gray-400 bg-gray-800 border-gray-900 rounded-md shadow-sm py-3 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-300 " 
                  />
                </div>
              </div>
              <div 
                class="flex-1" 
                v-else-if="storeState.card.details.goal.type"
              >
                <div class="mt-1 relative rounded-md shadow-sm">
                  <input 
                    type="text" 
                    autocomplete="off" 
                    v-model="storeState.card.details.goal.value" 
                    class="mt-1 block w-full border text-gray-100 bg-gray-800 border-gray-900 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-300 " 
                    placeholder="Value" 
                  />
                </div>
              </div>
              <div class="flex-1" v-else>
                <div 
                  @click="openCmdk({id: 6309})" 
                  class="bg-gray-800 py-3 text-gray-400 hover:text-gray-100 w-full rounded-md shadow-sm cursor-pointer flex items-center" 
                >
                  <div class="ml-3">
                    Select goal
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- URL -->
          <div v-if="showField('url', storeState.card)" class="grid grid-cols-12 sm:grid sm:grid-cols-12 gap-8 mt-4 ml-4">
            <div class="col-span-4 sm:col-span-4">
              <div class="bg-gray-900 py-3 text-gray-600 font-medium relative w-full border border-gray-900 rounded-md pl-3 text-left">
                URL
              </div>
            </div>
            <div class="col-span-8">
              <div 
                class="flex-1"
              >
                <div class="mt-1 relative rounded-md shadow-sm">
                  <input 
                    type="text" 
                    autocomplete="off" 
                    v-model="storeState.card.details.url.value" 
                    class="mt-1 block w-full border text-gray-100 placeholder-gray-700 bg-gray-800 border-gray-900 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-300 " 
                    placeholder="https://www.example.com/" 
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- TWITTER -->
          <div v-if="showField('twitter', storeState.card)" class="grid grid-cols-12 sm:grid sm:grid-cols-12 gap-8 mt-4 ml-4">
            <div class="col-span-4 sm:col-span-4">
              <div class="bg-gray-900 py-3 text-gray-600 font-medium relative w-full border border-gray-900 rounded-md pl-3 text-left">
                Twitter
              </div>
            </div>
            <div class="col-span-8">
              <div class="flex-1">
                <div class="relative rounded-md shadow-sm">
                  <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span class="text-gray-500">
                      @
                    </span>
                  </div>
                  <input 
                    type="text" 
                    autocomplete="off" 
                    v-model="storeState.card.details.twitter.value" 
                    class="pl-7 block w-full border text-gray-100 bg-gray-800 border-gray-900 rounded-md shadow-sm py-3 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-300 placeholder-gray-700" 
                    placeholder="jack" 
                    @keyup="getTwitterUser(storeState.card.details.twitter.value)"
                  />
                </div>
                <div 
                  class="text-gray-700"
                  v-if="storeState.card.details.twitter.data.followers_count">
                  {{storeState.card.details.twitter.data.name}} {{formatFollowerCount(storeState.card.details.twitter.data.followers_count)}} followers
                </div>
                <div 
                  class="text-gray-700"
                  v-if="storeState.card.details.twitter.data.description">
                  {{storeState.card.details.twitter.data.description}}
                </div>
              </div>
            </div>
          </div>
          <!-- TWEET STATUS -->
          <div
            v-if="showField('tweetStatus', storeState.card)" 
            class="grid grid-cols-12 sm:grid sm:grid-cols-12 gap-8 mt-4 ml-4"
          >
            <div class="col-span-4 sm:col-span-4">
              <div class="bg-gray-900 text-gray-600 font-medium relative w-full border border-gray-900 rounded-md pl-3 py-2 text-left">
                Status
              </div>
            </div>
            <div class="col-span-8">
              <div 
                class="bg-gray-800 py-3 text-gray-400 hover:text-gray-100 w-full rounded-md shadow-sm flex items-center" 
                v-if="true"
              >
                <div class="ml-3 flex" v-if="storeState.card.details.tweet.status && storeState.card.details.tweet.status.label">
                  <component 
                    :is="getStatusIcon(storeState.card.details.tweet.status)" 
                    class="mr-2 mt-1 h-4 w-4 text-gray-400 group-hover:text-gray-500 flex-shrink-0" 
                    aria-hidden="true" 
                  />
                  {{storeState.card.details.tweet.status.label}}
                </div>
                <div class="ml-3" v-else>
                  Select status
                </div>
              </div>
            </div>
          </div>
          <!-- TWEET URL -->
          <div
            v-if="showField('tweetStats', storeState.card)" 
            class="grid grid-cols-12 sm:grid sm:grid-cols-12 gap-8 mt-4 ml-4"
          >
            <div class="col-span-4 sm:col-span-4">
              <div class="bg-gray-900 text-gray-600 font-medium relative w-full border border-gray-900 rounded-md pl-3 py-2 text-left">
                Stats
              </div>
            </div>
            <div class="col-span-8">
              <div 
                class="bg-gray-800 py-3 text-gray-400 hover:text-gray-100 w-full rounded-md shadow-sm flex justify-between" 
                v-if="storeState.card.details.tweet.api.data"
                @click="openTweet"
              >
                <div class="ml-3">
                  <div>
                    <!-- Likes {{storeState.card.details.tweet.api.data.favorite_count}} Retweets {{storeState.card.details.tweet.api.data.retweet_count}} -->
                    <div class="flex justify-around text-gray-600">
                      <span class="mr-2">
                        <svg viewBox="0 0 24 24" aria-hidden="true" class="retweet mr-1 stroke-1 stroke-current"><g><path d="M23.77 15.67c-.292-.293-.767-.293-1.06 0l-2.22 2.22V7.65c0-2.068-1.683-3.75-3.75-3.75h-5.85c-.414 0-.75.336-.75.75s.336.75.75.75h5.85c1.24 0 2.25 1.01 2.25 2.25v10.24l-2.22-2.22c-.293-.293-.768-.293-1.06 0s-.294.768 0 1.06l3.5 3.5c.145.147.337.22.53.22s.383-.072.53-.22l3.5-3.5c.294-.292.294-.767 0-1.06zm-10.66 3.28H7.26c-1.24 0-2.25-1.01-2.25-2.25V6.46l2.22 2.22c.148.147.34.22.532.22s.384-.073.53-.22c.293-.293.293-.768 0-1.06l-3.5-3.5c-.293-.294-.768-.294-1.06 0l-3.5 3.5c-.294.292-.294.767 0 1.06s.767.293 1.06 0l2.22-2.22V16.7c0 2.068 1.683 3.75 3.75 3.75h5.85c.414 0 .75-.336.75-.75s-.337-.75-.75-.75z"></path></g></svg>
                        {{storeState.card.details.tweet.api.data.retweet_count}}
                      </span>
                      <span class="mr-2">
                        <svg  viewBox="0 0 24 24" aria-hidden="true" class="retweet mr-1 mr-1 stroke-1 stroke-current">
                          <g>
                            <path d="M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z">
                              </path>
                            </g>
                        </svg>
                        {{storeState.card.details.tweet.api.data.favorite_count}}
                      </span>
                    </div>
                  </div>
                </div>
                <ExternalLinkIcon class="h-5 w-5 text-gray-500 cursor-pointer mr-3" aria-hidden="true" />
              </div>
            </div>
          </div>
          <!-- {{storeState.card.details.tweet.api.data}} -->
          <!-- TWEET POSTING STUFF -->
          <div v-if="showField('postNow', storeState.card)" class="grid grid-cols-12 sm:grid sm:grid-cols-12 gap-8 mt-4 ml-4">
            <div class="col-span-4 sm:col-span-4">
              <div class="bg-gray-900 py-3 text-gray-600 font-medium relative w-full border border-gray-900 rounded-md pl-3 text-left">
                <!-- nothing -->
              </div>
            </div>
            <div class="col-span-8">
              <div class="">
                <button 
                  @click="postTweetNow"
                  class="w-full py-3 mt-3 px-4 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 shadow-sm hover:bg-blue-500 focus:outline-none focus:shadow-outline-blue focus:bg-blue-500 active:bg-blue-600 transition duration-150 ease-in-out"
                >
                  Post Now
                </button>
              </div>
            </div>
          </div>
          <!-- TWEET POST LATER STUFF -->
          <div v-if="showField('tweet', storeState.card)" class="grid grid-cols-12 sm:grid sm:grid-cols-12 gap-8 mt-4 ml-4">
            <div class="col-span-4 sm:col-span-4">
              <div class="bg-gray-900 py-3 text-gray-600 font-medium relative w-full border border-gray-900 rounded-md pl-3 text-left">
                <div v-if="storeState.card.details.tweet.status.id === 'in-queue'">
                  Post on
                </div>
              </div>
            </div>
            <div class="col-span-8">
              <div v-if="storeState.card.details.tweet.status.id === 'in-queue'" class="">
                <div 
                  @click="openCmdk({id: 8028})" 
                  class="bg-gray-800 py-3 text-gray-400 hover:text-gray-100 w-full rounded-md shadow-sm cursor-pointer flex items-center" 
                >
                  <div class="ml-3" v-if="storeState.card.details.tweet.postOn">
                    {{formatDueDate(storeState.card.details.tweet.postOn)}}
                  </div>
                  <div class="ml-3" v-else>
                    Select date
                  </div>
                </div>
              </div>
              <div v-else class="">
                <button 
                  @click="postTweetLater"
                  class="w-full py-3 mt-3 px-4 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 shadow-sm hover:bg-blue-500 focus:outline-none focus:shadow-outline-blue focus:bg-blue-500 active:bg-blue-600 transition duration-150 ease-in-out"
                >
                  Add to Queue
                </button>
              </div>
            </div>
          </div>
          <div v-if="showField('tweetClear', storeState.card)" class="grid grid-cols-12 sm:grid sm:grid-cols-12 gap-8 mt-4 ml-4">
            <div class="col-span-4 sm:col-span-4">
              <div class="bg-gray-900 py-3 text-gray-600 font-medium relative w-full border border-gray-900 rounded-md pl-3 text-left">
                <!-- nothing / blank -->
              </div>
            </div>
            <div class="col-span-8">
              <div class="">
                <button 
                  @click="clearSchedule"
                  class="w-full py-3 mt-3 px-4 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 shadow-sm hover:bg-blue-500 focus:outline-none focus:shadow-outline-blue focus:bg-blue-500 active:bg-blue-600 transition duration-150 ease-in-out"
                >
                  Clear Schedule
                </button>
              </div>
            </div>
          </div>
          <!-- TWEET POST STUFF old -->
          <!-- <div v-if="showField('tweet', storeState.card)" class="grid grid-cols-12 sm:grid sm:grid-cols-12 gap-8 mt-4 ml-4">
            <div class="col-span-6 sm:col-span-6">
              <button 
                @click="postTweetNow"
                class="py-2 mt-3 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 shadow-sm hover:bg-blue-500 focus:outline-none focus:shadow-outline-blue focus:bg-blue-500 active:bg-blue-600 transition duration-150 ease-in-out"
              >
                Post Now
              </button>
            </div>
            <div class="col-span-6 sm:col-span-6">
              <button 
                @click="postTweetLater"
                class="py-2 mt-3 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 shadow-sm hover:bg-blue-500 focus:outline-none focus:shadow-outline-blue focus:bg-blue-500 active:bg-blue-600 transition duration-150 ease-in-out"
              >
                Post Later
              </button>
            </div>
          </div> -->
        </div>
      </div>
      <div class="mt-4 mx-4">
        <!-- linked tags -->
        <span class="mr-3 mb-2 inline-flex rounded cursor-pointer items-center py-1 pl-3 pr-1 text-sm font-medium bg-gray-800 hover:bg-gray-700 text-gray-100"
          v-for="(linked, index) in storeState.card.details.linked"
          :key="index"
          @click="goToCard(linked, $event)"
        >
          {{getCard(linked).details.title}}
          <button 
            @click="removeLinked(linked)" 
            type="button" 
            class="preventEvent flex-shrink-0 ml-3 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-100 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-200"
          >
            <svg class="h-2 w-2 preventEvent" stroke="currentColor" fill="none" viewBox="0 0 8 8">
              <path class="preventEvent" stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
            </svg>
          </button>
        </span>
        <!-- linked search -->
        <div class="mt-4">
          <input 
            v-model="newLinked.search" 
            class="mousetrap p-0 bg-gray-900 outline-none border-0 border-transparent focus:border-transparent focus:ring-0 font-bold placeholder-gray-700 w-2/3"
            type="text"
            placeholder="+ Add backlink"
            @focus="newLinked.adding=true"
            @blur="onLinkSearchBlur"
            ref="backlinkSearch"
          >
        </div>
        <div class="h-64 overflow-y-scroll" v-if="newLinked.adding">
          <div 
            v-for="(linkOption, linkedIndex) in storeState.cards.values.filter(c => filterLinkedOptions(c)).slice(0, 10)"
            :key="linkedIndex"
            class="hover:bg-gray-800 rounded py-1 pl-2 cursor-pointer"
            @click="addLinked(linkOption)"
          >
            <span class="truncate">
              {{ linkOption.details.title ? linkOption.details.title : 'Untitled' }}
            </span>
          </div>
        </div>
        <!-- <div>
          {{storeState.card.details.fields}}
        </div> -->
      </div>
      <!-- <div v-if="(storeState.card.details.type === 'person' && getTwitterUrl())" class="mt-4 ml-4 max-h-48 max-w-xl">
        <a class="twitter-timeline" :href="getTwitterUrl()">Loading...</a> 
      </div> -->
      <div>
        <!-- {{storeState.card}} -->
      </div>
    </div>
    <div 
      class="absolute bg-gray-800 text-gray-500 rounded"
      :class="{hidden: !(atCt > 0)}"
      :style="{ top: suggestion.top, left: suggestion.left }"
    >
      <div 
        v-for="(linkOption, linkedIndex) in storeState.cards.values.filter(c => filterLinkedOptionsEditor(c)).slice(0, 10)"
        :key="linkedIndex"
        class="hover:bg-gray-600 rounded py-2 pl-2 px-2 cursor-pointer"
        @click="addLinkedEditor(linkOption)"
      >
        <span class="truncate">
          {{ linkOption.details.title ? linkOption.details.title : 'Untitled' }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import { store } from '../store.js';
import { reactive, ref } from 'vue'
import { Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
import axios from 'axios';
import SSF from 'ssf'
import Mousetrap from 'mousetrap'
import EasyMDE from "easymde";
import { Grid, html } from "gridjs";
import Popper from "vue3-popper";
import { supabase } from "../supabase"
import { debounce } from "debounce";
import CurrencyInput from '@/components/CurrencyInput.vue'
import MarkdownIt from 'markdown-it';

import { 
  UserIcon, 
  AnnotationIcon,
  HashtagIcon,
  TagIcon,
  EyeIcon,
  XIcon,
  ArchiveIcon,
  DocumentTextIcon,
  LightBulbIcon,
  OfficeBuildingIcon,
  CubeIcon,
  BookOpenIcon,
  SelectorIcon,
  CheckIcon,
  CheckCircleIcon,
  DuplicateIcon,
  InformationCircleIcon,
  PencilAltIcon,
  DotsHorizontalIcon,
  QuestionMarkCircleIcon,
  ClockIcon,
  ExternalLinkIcon,
  } from '@heroicons/vue/solid'

import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/vue";

export default {
  name: 'Metrics',
  props: {
      msg: String
  },
  components: {
    CurrencyInput,
    Popper,
    Listbox,
    ListboxLabel,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    XIcon,
    EyeIcon,
    ArchiveIcon,
    DocumentTextIcon,
    SelectorIcon,
    DuplicateIcon,
    InformationCircleIcon,
    CheckCircleIcon,
    PencilAltIcon,
    CheckIcon,
    DotsHorizontalIcon,
    Switch,
    ClockIcon,
    ExternalLinkIcon,
  },
  created() {
    this.getTwitterUser = debounce(this.getTwitterUser, 100);
    this.updateCard = debounce(this.updateCard, 200);
    this.setupMousetrap()
    this.checkUrl();
    this.emitter.on('got-cards', d => {
      console.log('got-cards', d)
      this.checkUrl()
      setTimeout(() => this.newLinked.adding=false, 100);
      setTimeout(() => this.adMdDblClick(), 1);
    });
  },
  mounted() {
    setTimeout(() => this.adMdDblClick(), 1);
  },
  errorCaptured: function(err) {
    console.log('errorCaptured', err.message);
    return false;
  },
  data() {
      return {
        store: store,
        storeState: store.state,
        creatingCard: false,
        atCt: 0,
        suggestion: {
          top: null,
          left: null,
          overlayLeft: null,
          overlayTop: null,
        },
        selectingChannel: false,
        forceShowDescription: false,
        someCurrency: 0,
        showMenu: false,
        newLinked: {
          adding: false,
          search: '',
          public_uuid: '',
          details: {
            title: '',
            description: '',
          }
        },
      }
  },
  methods: {
    login() {
      this.$router.push({ name: 'Login', query: { goto: 'Landing' }})
    },
    logout() {
      this.$router.push({ name: 'Logout', query: { goto: 'Landing' }})
    },
    goalTypes(type) {
      let alreadyAddedIds = this.storeState.card.details.fields.map(f => f.type.id)
      if (alreadyAddedIds.includes(type.id)) return false
      return true
    },
    getStatusIcon(status) {
      var icon = this.storeState.cardMeta.taskStatuses.find(s => s.id === status.id)
      if (icon && icon.icon) return icon.icon
      icon = this.storeState.cardMeta.tweetStatuses.find(s => s.id === status.id)
      if (icon && icon.icon) return icon.icon
      return false
    },
    clearSchedule() {
      this.storeState.card.details.tweet.status = this.storeState.cardMeta.tweetStatuses.find(s => s.id === 'new')
      return true
    },
    openTweet() {
      window.open(this.storeState.card.details.url.value, '_blank')
    },
    async getTweet() {
      console.log('getTweet...')
      const path = `${window.location.origin}/api`
      const d = {
        getTweet: true, 
        tweet: this.storeState.card.details.tweet.api.data,
        user: this.storeState.user,
      }
      const res = await axios.post(path, d)
      console.log('getTweet: ', res)
      if (res.data.ok) {
        this.storeState.card.details.tweet.api.data = res.data
        let updatedOn = new Date().toISOString();
        this.storeState.card.details.tweet.api.updatedOn = updatedOn
        console.log('show msg....')
      }
      return res
    },
    async postTweetNow() {
      console.log('postTweetNow...')
      if (this.storeState.card.details.md.length < 1) {
        let opts = {
          primary: 'Write your Tweet first!',
          type: 'error'
        }
        this.showMsg(this, opts)
        return false
      }
      this.storeState.card.details.tweet.status = this.storeState.cardMeta.tweetStatuses.find(s => s.id === 'posted')
      const path = `${window.location.origin}/api`
      const d = {
        postTweet: true, 
        tweet: {
          status: this.storeState.card.details.md
        },
        user: this.storeState.user,
      }
      const res = await axios.post(path, d)
      console.log('postedTweet: ', res.data)
      if (res.data.ok) {
        let tweet = res.data
        this.storeState.card.details.tweet.api.data = tweet
        let updatedOn = new Date().toISOString();
        this.storeState.card.details.tweet.api.updatedOn = updatedOn
        console.log('show msg....')
        let opts = {
          primary: 'Tweet posted!',
          type: 'success'
        }
        let tweetUrl = `https://twitter.com/${tweet.user.screen_name}/status/${tweet.id_str}`
        this.showMsg(this, opts)
        this.storeState.card.details.url.value = tweetUrl
      } else {
        var err = res.data.error
        if (err.errors && err.errors.length > 0) {
          err = err.errors[0]
          if (err.message) err = err.message
        }
        let opts = {
          primary: 'Error posting tweet',
          secondary: err,
          type: 'error'
        }
        this.showMsg(this, opts)
      }
      return true
    },
    async postTweetLater() {
      console.log('postTweetLater...')
      if (this.storeState.card.details.md.length < 1) {
        let opts = {
          primary: 'Write your Tweet first!',
          type: 'error',
          time: 7000,
        }
        this.showMsg(this, opts)
        return false
      }
      let today = new Date()
      this.storeState.card.details.tweet.postOn = today.setDate(today.getDate() + 1)
      this.storeState.card.details.tweet.status = this.storeState.cardMeta.tweetStatuses.find(s => s.id === 'in-queue')
      let opts = {
        primary: 'Tweet scheduled!',
        secondary: 'You can see all scheduled in your queue here.',
        type: 'success'
      }
      this.showMsg(this, opts)
      return true
    },
    showMsg(self, opts) {
      self.storeState.msg.show = true
      self.storeState.msg.primary = opts.primary
      self.storeState.msg.secondary = opts.secondary
      self.storeState.msg.type = opts.type
      setTimeout(() => self.storeState.msg.show = false, opts.time || self.storeState.msg.time);
    },
    filterFieldTypes(type) {
      let alreadyAddedIds = this.storeState.card.details.fields.map(f => f.type.id)
      if (alreadyAddedIds.includes(type.id)) return false
      return true
    },
    removeField(index) {
      this.storeState.card.details.fields.splice(index, 1);
    },
    updateDueDate(d) {
      console.log('updateDueDate', d)
      this.storeState.card.details.dueDate = d.dueDate;
    },
    adMdDblClick(e) {
      var self = this
      const el = document.getElementById('mdContainer')
      if (!el) return
      el.addEventListener('dblclick', (e) => {
        console.log('DblClick', e)
        let isPreviewActive = window.easyMDE.isPreviewActive()
        if (isPreviewActive) {
          self.storeState.card.details.editingMd = true
          window.easyMDE.togglePreview()
        }
      })
    },
    getEditableFields(obj) {
      let editable = ['goal', 'dueDate', 'twitter', 'priority']
      return Object.fromEntries(
        Object.entries(obj).filter(([key, value]) => editable.includes(key)) )
    },
    formatDueDate(date) {
      if (!date) return ''
      if (!date.toLocaleDateString) {
        date = new Date(date)
      }
      var options = { 
        weekday: 'short', 
        year: 'numeric', 
        month: 'short', 
        day: 'numeric' 
      };
      return date.toLocaleDateString("en-US", options)
    },
    openCmdk(cmd) {
      console.log('openCmdk', cmd)
    },
    formatFollowerCount(count) {
      if (count > 1000000) {
        return (count / 1000000).toFixed(1) + 'M'

      } else if (count > 1000) {
        return (count / 1000).toFixed(1) + 'K'
      
      } else {
        return count
      }
    },
    addField() {
      let firstType = this.storeState.cardMeta.fields[0]
      let field = {
        label: '',
        value: '',
        hovering: false,
        key: '',
        type: firstType,
      }
      this.storeState.card.details.fields.push(field)
    },
    mdTogglePreview() {
      console.log('mdTogglePreview...')
      let mdIsPreviewActive = window.easyMDE.isPreviewActive()
      let alreadyEditing = !mdIsPreviewActive
      if (this.storeState.card.details.editingMd && alreadyEditing) {
        window.easyMDE.togglePreview()
        this.storeState.card.details.editingMd = !this.storeState.card.details.editingMd //this.mdIsPreviewActive
        this.$forceUpdate()
      } else if (!this.storeState.card.details.editingMd && !alreadyEditing) {
        window.easyMDE.togglePreview()
        this.storeState.card.details.editingMd = !this.storeState.card.details.editingMd //this.mdIsPreviewActive
        this.$forceUpdate()
      } else if (this.storeState.card.details.editingMd && !alreadyEditing) {
        // window.easyMDE.togglePreview()
        this.storeState.card.details.editingMd = false
        this.$forceUpdate()
      } else if (!this.storeState.card.details.editingMd && alreadyEditing) {
        // window.easyMDE.togglePreview()
        this.storeState.card.details.editingMd = true
        this.$forceUpdate()
      }
      
    },
    filterLinkedOptions(linkOption) {
      let alreadyLinked = this.storeState.card.details.linked
      if (alreadyLinked.includes(linkOption.public_uuid)) return false
      if (linkOption.public_uuid === this.storeState.card.public_uuid) return false
      if (!linkOption.details.title) return false
      let st = this.newLinked.search.toLowerCase()
      let title = linkOption.details.title.toLowerCase()
      if (title.includes(st)) return true
      // let description = linkOption.details.description.toLowerCase()
      // if (description.includes(st)) return true
      return false
    },
    filterLinkedOptionsEditor(linkOption) {
      // let alreadyLinked = this.storeState.card.details.linked
      // if (alreadyLinked.includes(linkOption.public_uuid)) return false
      if (!window.easyMDE) return
      let cm = window.easyMDE.codemirror
      var line = cm.getCursor().line
      var st = cm.getLine(line)
      if (st.includes('@')) {
        st = st.split('@')[1]
        st = st.split(' ')[0]
      }
      st = st.toLowerCase()
      console.log('filterLinkedOptionsEditor: ', st)
      if (linkOption.public_uuid === this.storeState.card.public_uuid) return false
      if (!linkOption.details.title) return false
      let title = linkOption.details.title.toLowerCase()
      if (title.includes(st)) return true
      // let description = linkOption.details.description.toLowerCase()
      // if (description.includes(st)) return true
      return false
    },
    removeLinked(cardId) {
      let arr = this.storeState.card.details.linked.filter(l => l != cardId)
      console.log('removeLinked: ', arr, cardId)
      this.storeState.card.details.linked = arr
    },
    addLinkedEditor(newLink) {
      console.log('addLinkedEditor: ', newLink)
      let alreadyAdded = this.storeState.card.details.linked.includes(newLink.public_uuid)
      if (!alreadyAdded) this.storeState.card.details.linked.push(newLink.public_uuid)
      let backlinked = newLink.details.linked.includes(this.storeState.card.public_uuid)
      if (!backlinked) {
        newLink.details.linked.push(this.storeState.card.public_uuid)
        this.updateCard(newLink)
      }
      let searchTerm = '[['
      this.addLinkToCm(searchTerm, newLink)
      // TODO add [Card Title](wax.run url) to md
    },
    // autoSelected, searchTerm, wordRange, beforeSearch, line)
    addLinkToCm(searchTerm, newLink) {
      this.atCt = 0
      let editor = window.easyMDE.codemirror
      let doc = editor.getDoc();
      var cur = editor.getCursor()
      let line = editor.getRange({line: cur.line, ch: 0}, {line: cur.line, ch: cur.ch})
      var wordRange = editor.findWordAt(cur);
      let code = editor.getValue()
      let title = newLink.details.title || 'Untitled'
      // let addThis = `[${title}](wax.run/card/${newLink.public_uuid})`
      let addThis = `[${title}](/card/${newLink.public_uuid})`
      doc.replaceRange(addThis, wordRange.anchor, wordRange.head);
    },
    addLinked(newLink) {
      console.log('addLinked: ', newLink)
      let alreadyAdded = this.storeState.card.details.linked.includes(newLink.public_uuid)
      if (!alreadyAdded) this.storeState.card.details.linked.push(newLink.public_uuid)
      let backlinked = newLink.details.linked.includes(this.storeState.card.public_uuid)
      if (!backlinked) {
        newLink.details.linked.push(this.storeState.card.public_uuid)
        this.updateCard(newLink)
      }
      this.newLinked.search = ''
    },
    onLinkSearchBlur() {
      setTimeout(() => this.newLinked.adding=false, 100);
    },
    setChannel(channel) {
      this.storeState.card.details.channel = channel.public_uuid
      this.addLinked(channel)
      this.selectingChannel = false
      this.creatingCard = false
      setTimeout(() => this.$refs.cardTitle.focus(), 1);
      setTimeout(() => this.createMdEditor(), 1);
      
    },
    setType(type) {
      this.storeState.card.details.type = type.id;
      if (type.id === 'strategy') {
        this.selectingChannel = true
      } else {
        this.selectingChannel = false
        this.creatingCard = false
        setTimeout(() => this.$refs.cardTitle.focus(), 1);
        setTimeout(() => this.createMdEditor(), 1);
      }
    },
    showField(field, card) {
      if (field === 'twitter') {
        let types = ['person', 'company']
        return types.includes(card.details.type)
      } else if (field === 'goal') {
        let types = ['goal']
        return types.includes(card.details.type)
      } else if (['dueDate', 'goal', 'status'].includes(field)) {
        let types = ['goal', 'task', 'strategy']
        return types.includes(card.details.type)
      } else if (field === 'priority') {
        let types = ['task', 'strategy']
        return types.includes(card.details.type)
      } else if (field === 'postNow' && card.details.type === 'tweet') {
        if (card.details.tweet.status.id === 'new') return true
        return false
      } else if (field === 'tweetStats' && card.details.type === 'tweet') {
        if (card.details.tweet.status.id === 'posted') return true
        return false
      } else if (field === 'tweetClear' && card.details.type === 'tweet') {
        if (card.details.tweet.status.id === 'in-queue') return true
        return false
      } else if (field === 'tweetStatus') {
        let types = ['tweet']
        return types.includes(card.details.type)
      } else if (field === 'url') {
        let types = ['tweet']
        return !types.includes(card.details.type)
      }
      // } else if (field === 'url') {
      //   let exclude = ['criticalPath']
      //   return types.includes(card.details.type)
      // }
      return true
    },
    showTwitter(card) {
      let twitterTypes = ['person', 'company']
      return twitterTypes.includes(card.details.type)
    },
    getTwitterUrl() {
      let twitter = this.storeState.card.details.twitter
      if (!twitter) return false
      let url = `https://twitter.com/${twitter.replace('@', '')}`
      return url
    },
    async getTwitterUser(handle) {
      console.log('getting twitter handle: ', handle)
      if (!handle || handle.length < 1) {
        this.storeState.card.details.twitter.data = {}
        return false
      }
      // this.storeState.card.details.twitter = null
      // fetch("https://api.twitter.com/1.1/users/show.json?screen_name=twitterdev", {
      //   headers: {
      //     Authorization: "Bearer <API_TOKEN>"
      //   }
      // })
      const path = `https://www.wax.run/api`
      const d = {getTwitter: true, handle: handle}
      const res = await axios.post(path, d)
      console.log('got twitter: ', res.data)
      if (res.data.ok) {
        this.storeState.card.details.twitter.data = res.data.data
      } else {
        this.storeState.card.details.twitter.data = {}
      }
    },
    openCard() {
      if (this.storeState.card.details.type === 'person') {
        let twitter = this.storeState.card.details.twitter.replace('@', '')
        let url = `https://twitter.com/${twitter}`
        window.open(url, '_blank');
      }
    },
    setupMousetrap() {
      var self = this
      // Mousetrap.bind('4', function(e) { console.log('4'); });
      Mousetrap.bind('esc', function(e) { 
        if (e && e.preventDefault) e.preventDefault()
        self.$router.push({ name: 'Dashboard'})
      });
      Mousetrap.bind('command+enter', function(e) { 
        if (e && e.preventDefault) e.preventDefault()
        console.log('marktodo')
      });
      Mousetrap.bind('command+p', function(e) { 
        if (e && e.preventDefault) e.preventDefault()
        self.mdTogglePreview()
      });
    },
    getCard(cardId) {
      let c = this.storeState.cards.values.find(c => c.public_uuid === cardId)
      if (c) return c
      return this.storeState.cardTemplate
    },
    getCardType() {
      let t = this.storeState.cardMeta.types.find(t => t.id === this.storeState.card.details.type)
      if (t) return t
      return {}
    },
    checkUrl() {
      console.log('checkUrl0...', this.$route)
      if (this.$route.query.code) {
        console.log('checkUrl1...')
      }
      if (this.$route.params.id && this.storeState.cards.ok) {
        console.log('checkUrl2...')
        var card = this.storeState.cards.values.find(c => c.public_uuid === this.$route.params.id)
        if (card) {
          this.storeState.card = store.addMissingAttributes(card)
          setTimeout(() => this.createMdEditor(), 1);
        } else if (!this.$route.params.id || this.$route.params.id.length < 10) {
          this.$router.push({ name: 'Dashboard'})
        } else {
          // newcard createcard
          this.creatingCard = true
          this.storeState.card = store.deepCopy(this.storeState.cardTemplate)
          this.storeState.card.public_uuid = this.$route.params.id
          this.storeState.card.user_id = this.storeState.user.id
          this.storeState.card.details.status = this.storeState.cardMeta.taskStatuses.find(s => s.id === 'new')
          this.storeState.cards.values.unshift(this.storeState.card)
          setTimeout(() => this.createMdEditor(), 1);
        }
      }
    },
    removeElementsByClass(className){
      const elements = document.getElementsByClassName(className);
      while(elements.length > 0){
          elements[0].parentNode.removeChild(elements[0]);
      }
    },
    getCursorCoords(editor) {
      var coords = editor.cursorCoords(true);
      this.suggestion.left = coords.left + 'px'
      this.suggestion.top = coords.top + 25 + 'px'
      this.suggestion.overlayLeft = coords.left + 10 + 'px'
      this.suggestion.overlayTop = coords.top + 'px'
    },
    renderMd(plainText) {
      console.log('renderMd', plainText)
      const md = new MarkdownIt();
      // add ${someVar} to the md
      var someVar = 'AHHHHHHHHH!!!!!!!'
      // plainText = eval(plainText)
      let replaceVars = (str, obj) => str.replace(/\${(.*?)}/g, (x,g)=> obj[g]);
      plainText = replaceVars(plainText, {someVar: someVar} );
      var result = md.render(plainText);
      return result
    },
    createMdEditor() {
      let mdEl = document.getElementById('mdEditor')
      if (!mdEl) return
      if (window.easyMDE) {
        console.log('easyMDE already exists, destroying...')
        window.easyMDE.toTextArea();
        window.easyMDE = null;
        this.removeElementsByClass('EasyMDEContainer')
      }
      var self = this
      window.easyMDE = new EasyMDE({
        element: mdEl,
        previewClass: ["editor-preview", "prose", "dark:prose-light", "prose-blue"],
        initialValue: this.storeState.card.details.md || '',
        placeholder: 'Write here (Markdown is supported)',
        showIcons: ['strikethrough'],
        shortcuts: {
          "strikethrough": "Cmd-Enter", 
        },
        // previewRender: function(plainText) {
        //   return self.renderMd(plainText); // Returns HTML from a custom parser
        // },
        // hideIcons: ['guide', 'heading', 'bold', 'italic', 'quote', 'bullets', 'unordered-list', 'ordered-list'],
      });
      window.easyMDE.value(this.storeState.card.details.md || '')
      
      window.easyMDE.codemirror.on("change", function(event) {
        console.log('cm change', event)
        self.storeState.card.details.md = window.easyMDE.value()
        // self.storeState.card.details.html = window.easyMDE.previewRender()
      });
      window.easyMDE.codemirror.on("keydown", function (cm, event) {
        var keycode = event.which;
        // console.log("keyup", keycode);
        self.getCursorCoords(window.easyMDE.codemirror)
        if (keycode === 50) {
          // open linker this uses the '@' symbol
          self.atCt+= 1
          if (self.atCt === 1) {
            // cm.getCursor()
          }
        } else if (keycode === 13) {
          self.atCt = 0
        } else {
          // self.atCt = 0
        }
      });
      window.easyMDE.codemirror.options.extraKeys['Cmd-Enter'] = function (cm) {
        console.log('cmd enter')
        let lineNumber = cm.getCursor().line
        var line = cm.getLine(lineNumber)
        var todoIndex = line.indexOf('- [ ] ')
        var todoDoneIndex = line.indexOf('- [x] ')
        if (todoIndex >= 0) {
          cm.replaceRange('- [x] ', {line: lineNumber, ch: todoIndex}, {line: lineNumber, ch: todoIndex+6})
        } else if (todoDoneIndex >= 0) {
          cm.replaceRange('- [ ] ', {line: lineNumber, ch: todoDoneIndex}, {line: lineNumber, ch: todoDoneIndex+6})
        } else {
          self.mdTogglePreview()
        }
      };
      window.easyMDE.codemirror.options.extraKeys['Esc'] = function (cm) {
        console.log('Esc')
        if (self.atCt > 0) {
          self.atCt = 0
          return
        }
        self.$refs.cardTitle.focus()
        self.$refs.cardTitle.blur()
      };
      // window.easyMDE.codemirror.setOption("extraKeys", {
      //   Esc: function(cm) {
      //     console.log('Esc')
      //     self.$refs.cardTitle.focus()
      //     self.$refs.cardTitle.blur()
      //   },
      //   'Cmd+P': function(cm) {
      //     console.log('Cmd+P...')
      //     self.mdTogglePreview()
      //   },
      // });
      if (self.storeState.card.is_public) {
        // self.mdTogglePreview()
        window.easyMDE.togglePreview()
        return
      } else if (!self.storeState.card.details.editingMd) {
        // self.mdTogglePreview()
        window.easyMDE.togglePreview()
        return
      }
    },
    // duplicateCard() {
    copyCard(oldCard) {
      let newCard = store.deepCopy(oldCard)
      delete newCard.id
      newCard.public_uuid = store.uuidv4()
      newCard.details.title = `Copy of ${newCard.details.title}`
      newCard.details.status = this.storeState.cardMeta.taskStatuses.find(s => s.id === 'new')
      this.storeState.cards.values.unshift(newCard)
      this.$router.push({ name: 'Card', params: { id: newCard.public_uuid }})
    },
    archiveCard(card) {
      card.is_archived = true
      this.storeState.cards.values = this.storeState.cards.values.filter(c => card.public_uuid !== c.public_uuid)
      this.$router.push({ name: 'Dashboard'})
    },
    goToCard(cardId, e) {
      if (e.target && e.target.classList.contains('preventEvent')) {
        console.log('goToCard', e)
        return
      }
      this.$router.push({ name: 'Card', params: { id: cardId }})
      this.checkUrl()
    },
    timeSince(date) {
      var seconds = Math.floor((new Date() - date) / 1000);
      var interval = seconds / 31536000;
      if (interval > 1) {
        return Math.floor(interval) + " years";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " months";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " days";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " hours";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " minutes";
      }
      return Math.floor(seconds) + " seconds";
    },
    async updateCard(card) {
      delete card.hovering
      card = store.deepCopy(card)
      card.updated_on = new Date().toISOString();
      let ci = this.storeState.cards.values.findIndex((c => c.public_uuid == card.public_uuid));
      this.storeState.cards.values[ci] = card
      // card.updated_on = new Date().toISOString();
      const result = await supabase
        .from('cards')
        .update(card)
        .match({id: card.id})
      console.log('updated card...')
      if (result.error) {
        console.error('updateCard error', result.error)
        if (result.error.message === 'JWT expired') {
          this.$router.push({ name: 'Login' })
        }
        return
      }
    },
    async insertCard(card) {
      delete card.hovering
      const result = await supabase
              .from('cards')
              .insert([
                this.storeState.card
              ])
      console.log('insertCard', result)
      if (result.error) {
        console.error('insertCard error', result.error)
        console.log('insert card attempt', card)
        return
      } else {
        console.log('insertCard success', result.data[0].id)
        this.storeState.card = result.data[0]
      }
    },
    ssfFormatter(format, val) {
      // return (m / 1000).toFixed(1)
      return SSF.format(format, val)
    },
  },
  computed: {
    // mdIsPreviewActive() {
    //   return window.easyMDE.isPreviewActive()
    // }
  },
  watch: {
    'storeState.card': {
        handler: function () {
          console.log('storeState.card update...')
          if (!this.storeState.card.id) {
            this.insertCard(this.storeState.card)
          } else {
            this.updateCard(this.storeState.card)
          }
        },
        deep: true
    },
    'storeState.card.details.tweet.status.id': {
        handler: function (newId, oldId) {
          console.log('storeState.card.details.tweet.status.id update...', newId)
          this.storeState.card.details.tweet.status = this.storeState.cardMeta.tweetStatuses.find(s => s.id === newId)
        },
        deep: false
    },
    'storeState.card.details.url.value': {
        handler: function (newVal, oldVal) {
          console.log('storeState.card.details.url.value update...', newVal)
          if (newVal.includes('twitter.com')) {
            this.getTweet(newVal)
          }
        },
        deep: false
    },
    '$route.params.id': function (newId, oldId) {
      console.log('uuid changed', newId, oldId)
      this.checkUrl()
    },
  },
}
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  #vg-tooltip-element.vg-tooltip.custom-theme {
    color: #f7fafc;
  }
  #vg-tooltip-element table tr td.key {
    color: #718096;
  }
  #vg-tooltip-element {
    background-color: #2d3748;
    color: #f7fafc;
    border: none;
  }
  .editor-toolbar {
    background-color: #1a202c;
    color: #f7fafc;
    border: none;
    display: none;
  }
  .editor-toolbar.fullscreen {
    background-color: #1a202c;
    color: #f7fafc;
    border: none;
  }
  .CodeMirror-selected {
    background-color: #63b3ed !important; 
    /* bg-gray-700 */
    /* blue-400 #63b3ed */
    /* blue-300 #90cdf4 */
    /* blue-200 #bee3f8 */
  }
  .editor-preview pre {
    @apply bg-gray-800 px-3 py-2;
  }
  .prose code::before {
    content: none;
  }
  .prose code::after {
    content: none;
  }
  .EasyMDEContainer .CodeMirror-fullscreen {
    background-color: #1a202c;
    color: #f7fafc;
    border: none;
  }
  .editor-statusbar {
    @apply hidden;
  }
  .editor-preview {
    background-color: #1a202c;
    color: #f7fafc;
    border: none;
    padding: 0px !important;
  }
  .cm-s-easymde {
    background-color: #1a202c;
    color: #f7fafc;
    border: none !important;
    caret-color: #f7fafc !important;
    padding: 0px !important;
  }
  .CodeMirror-line {
    padding: 0px !important;
  }
  .CodeMirror-cursor {
    border-left: 1px solid #f7fafc !important;
  }
  .CodeMirror-placeholder {
    color: #718096 !important; 
  }
  .editor-toolbar button.active, .editor-toolbar button:hover {
    background-color: #718096 !important;
    border: none !important;
    @apply text-white border-0 border-transparent focus:border-transparent focus:ring-0;

  }
  button {
    outline: none !important;
  }
  focus:button {
    outline: none !important;
  }
  .prose > ul > li > *:last-child {
    margin-bottom: 0.2em !important;
  }
  .prose > ul > li > *:first-child {
    margin-top: 0.2em !important;
  }
  ol {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .prose-blue a {
    @apply hover:text-gray-200
  }
  
</style>
