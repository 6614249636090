<template>
  <div class="bg-gray-900 mx-8 mt-4 text-gray-100">
    <div v-if="!storeState.cards.ok">
      <div class="flex justify-center space-y-8 w-full pt-32">
        <svg class="animate-spin -ml-1 mr-3 h-20 w-20 text-gray-100" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </div>
    </div>
    <div v-else class="">      
      <div class="flex justify-between">
        <div 
          class="bg-gray-900 flex-grow cursor-pointer p-0 outline-none border-0 border-transparent focus:border-transparent focus:ring-0 text-4xl font-bold text-gray-100"
        >
        {{storeState.view.label}}
        </div>
        <div class="justify-end">
          <!-- <button 
            @click="newCard"
            class="py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 shadow-sm hover:bg-blue-500 focus:outline-none focus:shadow-outline-blue focus:bg-blue-500 active:bg-blue-600 transition duration-150 ease-in-out"
          >
            New Card
          </button> -->
        </div>
      </div>
      <!-- MESSAGE FOR VIEW -->
      <div>
        <div v-if="storeState.view.id === 'channel'">
          <div class="bg-gray-700 rounded p-2 mt-4 flex">
            
            <LightBulbIcon class="h-6 w-6 mr-2 -mb-1" aria-hidden="true" />
            <div>
              You should create strategies for each channel. Don't let your biases rule a channel out before ideation.
            </div>
            
          </div>
          
        </div>
      </div>
      <div class="mt-10">
        <div 
          class="mt-4"
          v-for="(card, index) in storeState.cards.values.filter(c => filterForView(c))"
          :key="index"
          @mouseenter="card.hovering=true"
          @mouseleave="card.hovering=false"
        >
          <div @click="goToCard(card, $event)" class="flex text-lg font-bold cursor-pointer hover:text-gray-400">
            <component 
              :is="getCardType(card).icon" 
              class="mr-1 h-4 w-4 text-gray-400 group-hover:text-gray-500 flex-shrink-0" 
              aria-hidden="true" 
              style="margin-top: 5px;"
            />
            <div class="break-all">
              {{card.details.title || 'Untitled'}}
            </div>
            <div>
              
            </div>
          </div>
          <div style="" class="text-gray-600 break-all description-lines" v-html="getDescription(card)">
            
          </div>
          <div class="mt-4">
            <span class="mr-3 mb-2 inline-flex rounded cursor-pointer items-center py-1 pl-3 pr-1 text-sm font-medium bg-gray-800 text-gray-400 hover:text-gray-100"
              v-for="(linked, index) in card.details.linked"
              :key="index"
              @click="goToLinkedCard(linked, $event)"
            >
              {{getCard(linked).details.title}}
              <button 
                @click="removeLinked(linked, card)" 
                type="button" 
                class="preventEvent flex-shrink-0 ml-3 h-4 w-4 rounded-full inline-flex items-center justify-center text-gray-100 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-200"
              >
                <svg class="h-2 w-2 preventEvent" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                  <path class="preventEvent" stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                </svg>
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { store } from '../store.js';
import axios from 'axios';
import { supabase } from "../supabase"
import Mousetrap from 'mousetrap'
import SSF from 'ssf'

import { Grid, html } from "gridjs";
import MarkdownIt from 'markdown-it';
// import "gridjs/dist/theme/mermaid.css";

import { 
  CheckCircleIcon,
  CurrencyDollarIcon,
  LightBulbIcon,
  UserIcon,
  } from '@heroicons/vue/solid'

export default {
  name: 'Metrics',
  props: {
      msg: String
  },
  components: {
    CurrencyDollarIcon,
    LightBulbIcon,
    UserIcon,
    // Switch,
    // SwitchGroup,
    // SwitchLabel,
  },
  created() {
    let view = this.storeState.views[this.$route.params.id]
    if (view) this.storeState.view = view
    this.setupMousetrap()
  },
  mounted() {
    
    this.emitter.on('user-login', d => {
      // this.getMetrics()
    });
  },
  data() {
      return {
        store: store,
        storeState: store.state,
        gridHover: null,
        searchTerm: '',
      }
  },
  methods: {
    login() {
      this.$router.push({ name: 'Login', query: { goto: 'Landing' }})
    },
    logout() {
      this.$router.push({ name: 'Logout', query: { goto: 'Landing' }})
    },
    getTaskTimeSince(card) {
      if (card.details.dueDate) {
        var t = store.timeSince(new Date(card.details.dueDate))
        return t
      }
      return {ago: false}
    },
    goToStrategies() {
      
    },
    goToTrending() {
      
    },
    goToChannels() {

    },
    getTaskDueStatus(card) {
      if (card.details.dueDate) {
        var t = store.timeSince(new Date(card.details.dueDate))
        console.log('t: ', t)
        t = store.timeSinceToStr(t)
        return `${t}`
      }
      return ''
    },
    getCardDescription(card) {
      return card.details.title || 'Untitled'
    },
    filterStrategies(c) {
      if (c.details.type != 'strategy') return false
      // if (c.details.status && ['new', 'in-progress'].includes(c.details.status.id)) return true
    },
    filterTasks(c) {
      if (c.details.type != 'task') return false
      if (c.details.status && ['new', 'in-progress'].includes(c.details.status.id)) return true
    },
    setupMousetrap() {
      var self = this
      // Mousetrap.bind('4', function(e) { console.log('4'); });
      Mousetrap.bind('esc', function(e) { 
        if (e && e.preventDefault) e.preventDefault()
        self.$router.push({ name: 'Dashboard' })

      });
      // Mousetrap.bind('/', function(e) { 
      //   if (e && e.preventDefault) e.preventDefault()
      //   if (self.$refs.searchInput && self.$refs.searchInput.focus) self.$refs.searchInput.focus()
      // });
    },
    addGoal() {

    },
    openCmdk(cmd) {
      console.log('openCmdk', cmd)
    },
    getGoalType(card) {
      if (!card.details.goal) return {}
      if (!card.details.goal.type) return {}
      return card.details.goal.type
    },
    getGoal(card) {
      if (!card.details.fields) return `${card.details.title}`
      let field = card.details.fields.find(f => f.type.goal)
      if (field && field.value) {
        // let val = SSF.format('#,##0', field.value)
        let val = store.formatCash(field.value)
        let dd = store.formatDateShort(card.details.dueDate)
        return `${field.type.label} to ${val} by ${dd}`
      }
      return `${card.details.title}`
    },
    ssfFormatter(format, val) {
      // return (m / 1000).toFixed(1)
      return SSF.format(format, val)
    },
    getCardType(card) {
      let t = this.storeState.cardMeta.types.find(t => t.id === card.details.type)
      if (t) return t
      return {}
    },
    async updateCard(card) {
      delete card.hovering
      let ci = this.storeState.cards.values.findIndex((c => c.public_uuid == card.public_uuid));
      this.storeState.cards.values[ci] = card
      const result = await supabase
        .from('cards')
        .update(card)
        .match({id: card.id})
      console.log('updateCard', result)
      if (result.error) {
        console.error('updateCard error', result.error)
        if (result.error.message === 'JWT expired') {
          this.$router.push({ name: 'Login' })
        }
        return
      }
    },
    newCard() {
      // let cardId = store.uuidv4()
      // this.$router.push({ name: 'Card', params: { id: cardId }})
      // this.openCmdk(6322)
    },
    removeLinked(linkedId, card) {
      let arr = card.details.linked.filter(l => l != linkedId)
      card.details.linked = arr
      this.updateCard(card)
    },
    getDescription(card) {
      if (!card.details.md) return ''
      const md = new MarkdownIt();
      var result = md.renderInline(card.details.md);
      result = result.replace('# ', '').replace('## ', '').replace('### ', '')
      return result
    },
    getPeople(card) {
      if (card.details.people && card.details.people.length > 0) {
        const people = []
        for (let index = 0; index < card.details.people.length; index++) {
          const personId = card.details.people[index];
          const person = this.storeState.cards.values.find(c => c.public_uuid === personId)
          people.push(person)
        }
        return people
      }
    },
    goToCard(card, event) {
      console.log('goToCard: ', card)
      this.$router.push({ name: 'Card', params: { id: card.public_uuid }})
    },
    goToLinkedCard(cardId, event) {
      console.log('goToLinkedCard: ', cardId)
      this.$router.push({ name: 'Card', params: { id: cardId }})
    },
    goToPerson(person) {
      console.log('goToPerson: ', person)
      this.storeState.card = person
      this.$router.push({ name: 'Card', params: { id: person.public_uuid }})
    },
    getCard(cardId) {
      let c = this.storeState.cards.values.find(c => c.public_uuid === cardId)
      if (c) return c
      return this.storeState.cardTemplate
    },
    filterForView(card) {
      let cardTypeViews = ['channel', 'strategy', 'task']
      if (cardTypeViews.includes(this.storeState.view.id)) {
        let viewId = this.storeState.view.id
        if (viewId === card.details.type) return 100
        return 0
      } else if (this.storeState.view.id === 'queue') {
        if (!card.details.tweet) return 0
        if (card.details.tweet.status.id === 'in-queue') return 100
        if (card.details.type === 'tweet') return 0
        return 0
      }
      return 100
    },
  },
  watch: {
    '$route.params.id': function (newId, oldId) {
      console.log('view id changed', newId, oldId)
      let view = this.storeState.views[this.$route.params.id]
      if (view) this.storeState.view = view
    },
  },
}
/* eslint-disable no-unused-vars */
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  #vg-tooltip-element.vg-tooltip.custom-theme {
    color: #f7fafc;
  }
  #vg-tooltip-element table tr td.key {
    color: #718096;
  }
  #vg-tooltip-element {
    background-color: #2d3748;
    color: #f7fafc;
    border: none;
  }
  .description-lines {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
</style>
