<template>
<!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ]
  }
  ```
-->
<div class="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
  <div v-if="!checkedIfLoggedIn" class="flex justify-center max-w-md space-y-8 w-full">
    <svg class="animate-spin -ml-1 mr-3 h-20 w-20 text-gray-100" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  </div>
  <div v-else class="max-w-md w-full space-y-8">
    <div>
      <img class="mx-auto h-12 w-auto" src="/wax-logo.png"  alt="Wax Logo">
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-100">
        Sign in or create an account
      </h2>
      <!-- <p class="mt-2 text-center text-sm text-gray-600">
        Or
        <a href="#" class="font-medium text-gray-600 hover:text-gray-500">
          start your 14-day free trial
        </a>
      </p> -->
    </div>
    <!-- <div class="mt-8 space-y-6">
      <input type="hidden" name="remember" value="true">
      <div class="rounded-md shadow-sm -space-y-px">
        <div>
          <label for="email-address" class="sr-only">Email address</label>
          <input v-model="credentials.email" ref="email" id="email-address" name="email" type="email" autocomplete="email" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm" placeholder="Email address">
        </div>
        <div v-if="!forgotPasswordFlow">
          <label for="password" class="sr-only">Password</label>
          <input v-model="credentials.password" id="password" name="password" type="password" autocomplete="current-password" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-gray-500 focus:border-gray-500 focus:z-10 sm:text-sm" placeholder="Password">
        </div>
      </div>

      <div class="flex items-center justify-between">
        <div v-if="!forgotPasswordFlow" class="flex items-center">
          <input id="remember_me" name="remember_me" type="checkbox" class="h-4 w-4 text-gray-600 focus:ring-gray-500 border-gray-300 rounded">
          <label for="remember_me" class="ml-2 block text-sm text-gray-100">
            Remember me
          </label>
        </div>

        <div class="text-sm">
          <a v-if="!forgotPasswordFlow && !storeState.doingRecovery" @click="forgotPassword" target="_blank" class="font-medium text-gray-600 hover:text-gray-500 cursor-pointer">
            Forgot your password?
          </a>
        </div>
      </div>

      <div v-if="creatingAccount">
        <button @click="handleSignup" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
          <span class="absolute left-0 inset-y-0 flex items-center pl-3">
            <svg class="h-5 w-5 text-gray-500 group-hover:text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
            </svg>
          </span>
          Create account
        </button>
      </div>
      <div v-else-if="forgotPasswordFlow">
        <button @click="forgotPassword" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
          <span class="absolute left-0 inset-y-0 flex items-center pl-3">
            <svg class="h-5 w-5 text-gray-500 group-hover:text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
            </svg>
          </span>
          Send link
        </button>
      </div>
      <div v-else>
        <button @click="handleLogin" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
          <span class="absolute left-0 inset-y-0 flex items-center pl-3">
            <svg class="h-5 w-5 text-gray-500 group-hover:text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
            </svg>
          </span>
          Sign in
        </button>
      </div>
      <div>
        <a class="text-gray-600 hover:text-gray-400 cursor-pointer" @click="creatingAccount=true">New to Wax? Create an account.</a>
      </div>
    </div> -->
    <div class="mt-8 space-y-6">
      <div>
        <div>
          <span>
            <button 
              @click="handleGoogleLogin"
              class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              <div class="flex">
                <div class="pr-4">
                  <svg width="21" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="google" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512">
                    <path fill="currentColor" d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z">
                    </path>
                  </svg>
                </div>
                <div>
                  Sign in with Google
                </div>
              </div>
            </button>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { store } from '../store.js';
import { ref } from 'vue'
import { supabase } from "../supabase"

export default {
  name: 'Login',
  props: {
      msg: String
  },
  created() {
    this.checkUrl()
    this.storeState.showLanding = false
    this.emitter.on('user-login', d => {
      console.log('user-login', d)
      this.$router.push({ name: 'Dashboard'})
      // if (this.storeState.twitterFlow) {
      //   this.$router.push({ name: 'Settings'})
      // } else {
        
      // }
    });
  },
  methods: {
    checkUrl() {
      console.log('checkUrl...')
      if (this.storeState.doingRecovery) {
        let opts = {
          primary: 'Choose a new password.',
          secondary: 'Use a strong random password.',
        }
        this.showMsg(this, opts)
      }
      if (this.$route.query.apiKey) {
        console.log('checkUrl apiKey...')
      }
      if (this.storeState.isLoggedIn) {
        console.log('checkUrl isLoggedIn...')
        this.$router.push({ name: 'Dashboard'})
      }
    },
    showMsg(self, opts) {
      self.storeState.msg.show = true
      self.storeState.msg.primary = opts.primary
      self.storeState.msg.secondary = opts.secondary
      self.storeState.msg.type = opts.type
      setTimeout(() => self.storeState.msg.show = false, self.storeState.msg.time);
    },
    forgotPassword() {
      this.forgotPasswordFlow = true
      if (this.credentials.email && this.credentials.email.length > 2 && this.credentials.email.includes('@')) {
        let redirectTo = window.location.origin
        const { data, error } = supabase.auth.api.resetPasswordForEmail(
          this.credentials.email, 
          {redirectTo: redirectTo}
          )
        if (error) {
          console.error('forgotPassword error', error)
          let opts = {
            primary: 'Forgot password error',
            secondary: 'Please contact support at contact@wax.run',
            type: 'error'
          }
          this.showMsg(this, opts)
        }
        let opts = {
          primary: 'Please check your email.',
          secondary: 'It might be in spam.',
        }
        this.showMsg(this, opts)
        // this.loginMessage = 'Please check your email (including spam).'
      } else {
        let opts = {
          primary: 'Please enter a valid email address.',
          type: 'error'
        }
        this.showMsg(this, opts)
      }
    },
    async handleGoogleLogin() {
      var redirectTo = `${window.location.origin}/dashboard`
      if (this.$route.query.goto && this.$route.query.goto === 'google2') {
        redirectTo = `${window.location.origin}/google2`
      }
      console.log('handleGoogleLogin redirect...', redirectTo)
      const { user, session, error } = await supabase.auth.signIn(
        {
          provider: 'google'
        },
        {
          scope: 'profile email',
          prompt: 'select_account',
          redirectTo: redirectTo
        }
      );
      if (error) {
        console.error('handleGoogleLogin error', error)
        let opts = {
          primary: 'Google login error',
          secondary: 'Please contact support at hello@wax.run'
        }
        this.showMsg(this, opts)
      }
    },
    async handleLogin() {
      try {
        console.log('recoverySession...', this.storeState.recoverySession)
        if (this.storeState.recoverySession) {
          let accessToken = this.storeState.recoverySession.accessToken
          this.storeState.recoverySession = null
          this.storeState.doingRecovery = false
          this.storeState.checkedLogin = true
          const { error, data } = await supabase.auth.api
            .updateUser(accessToken, { password :  this.credentials.password})
          console.log('recoverySession complete.')
          if (error) {
            alert('Error logging in: ' + error.message)
            console.error('recoverySession error', error)
          }
          let opts = {
            primary: 'Password updated!',
          }
          this.showMsg(this, opts)
          this.emitter.emit('check-login');
          this.$router.push({ name: 'Dashboard'})
          console.log('recoverySession complete...')
          return
        }
        let redirectTo = window.location.origin
        console.log('redirectTo...', redirectTo)
        const { error, user } = await supabase.auth.signIn(
          {
            email: this.credentials.email,
            password: this.credentials.password,
          },
          {
            redirectTo: redirectTo
          }
        )
        if (error) {
          alert('Error logging in: ' + error.message)
        }
        // No error throw, but no user detected so send magic link
        if (!error && !user) {
          // alert('Check your email for the login link!')
          let opts = {
            primary: 'Please check your email for the login link.',
            secondary: 'It might be in spam.',
          }
          this.showMsg(this, opts)
        }
      } catch (error) {
        console.error('Error thrown:', error.message)
        alert(error.error_description || error)
      }
    },
    async handleSignup() {
      try {
        const { email, password } = this.credentials
        // prompt user if they have not filled populated their credentials
        if (!email || !password) {
          // alert('Please provide both your email and password.')
          let opts = {
            primary: 'Please provide both your email and password.',
            type: 'error',
          }
          this.showMsg(this, opts)
          return
        }
        const { error } = await supabase.auth.signUp({ email, password })
        if (error) {
          alert(error.message)
          console.error(error, error.message)
          return
        }
        // alert('Signup successful, confirmation mail should be sent soon!')
        let opts = {
          primary: 'Signup successful!',
          secondary: 'Please check your email for the confirmation link.',
        }
        this.showMsg(this, opts)
      } catch (err) {
        alert('Fatal error signing up')
        console.error('signup error', err)
      }
    },
    async handleLogout() {
      // this.render();
    },
    goHome() {
      this.$router.push({ name: 'Landing', params: { userId: 123 }})
    },
  },
  computed: {
    appReady() {
      if (this.loan.email.length > 3) return true
      return false
    }
  },
  watch: {
    'companyStage': {
        handler: function () {
          this.filterFunders()
        },
        deep: false
    },
  },
  data() {
      return {
        store: store,
        creatingAccount: false,
        forgotPasswordFlow: false,
        loginError: null,
        loginM: null,
        credentials: {
          email: '',
          password: '',
        },
        storeState: store.state,
        checkedIfLoggedIn: true,
        email: '',
        appError: false,
        submitted: false,
        updatingPayoutPeriod: false,
        loan: {
          email: '',
          mrr: 50000,
          amount: 250000,
          rate: .15,
          multiple: 15,
          payoutPeriod: {label: '12 months', months: 12},
          term: 36,
          paybackMonths: 36,
          years: [
            {label: 'Year 1'},
            {label: 'Year 2'},
            {label: 'Year 3'},
            {label: 'Total'},
          ],
          months: [],
          metricCalcType: 'stripe',
          mrrs: [
            {label: 'Current MRR', value: 0},
            {label: '3 months ago', value: 0},
            {label: '6 months ago', value: 0},
            {label: '12 months ago', value: 0},
            {label: '24 months ago', value: 0},
          ]
        },
        payoutPeriods: [
          {label: '12 months', months: 12},
          {label: '18 months', months: 18},
          {label: '24 months', months: 24}
        ],
      }
  }
}
/* eslint-enable no-unused-vars */

</script>

