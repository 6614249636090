<template>
<div class="min-h-screen flex pt-24 justify-center bg-gray-900 py-12 px-4 sm:px-6 lg:px-8">
  <div v-if="storeState.loading">
    <div class="flex justify-center space-y-8 w-full pt-32">
      <svg class="animate-spin -ml-1 mr-3 h-20 w-20 text-gray-100" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>
  </div>
  <div v-else class="flex justify-center max-w-md space-y-8 w-full">
    <ThumbUpIcon class="mb-1 inline h-16 w-16 text-gray-500" aria-hidden="true" />
  </div>
</div>
</template>

<script>
/* eslint-disable no-unused-vars */
import axios from 'axios';
import { store } from '../store.js';
import { supabase } from '../supabase'

import { 
  ThumbUpIcon
  } from '@heroicons/vue/solid'

export default {
  name: 'Callback',
  components: {
    ThumbUpIcon,
  },
  created() {
    this.checkUrl()
    // this.getCallback()
  },
  methods: {
    async checkUrl() {
      let user = await supabase.auth.user()
      var googleCallback = false
      if (window.location.href.includes('/google2')) {
        googleCallback = true
      }
      if (googleCallback && this.$route.query.code) {
        console.log('got googleCallback...')
        const path = `${window.location.origin}/api`
        const d = {
          step2: true, 
          type: 'google',
          user: user,
          code: this.$route.query.code,
        }
        const res = await axios.post(path, d)
        console.log('got google: ', res.data)
        if (res.data.ok) {
          this.storeState.settings.google = res.data.data
        } else {
          this.storeState.settings.google = {}
        }
      } else if (googleCallback) {
        console.log('no code, but google2...')
        this.storeState.loading = true
        const path = `${window.location.origin}/api`
        const d = {
          step1: true, 
          type: 'google',
          user: this.storeState.user,
        }
        const res = await axios.post(path, d)
        console.log('doOnboardStep connectSheets', res.data)
        window.location.href = res.data.url
        // this.storeState.loading = false
      }
    },
  },
  computed: {
  },
  watch: {
  },
  data() {
      return {
        store: store,
        storeState: store.state,
        checkedIfLoggedIn: false,
      }
  }
}
/* eslint-enable no-unused-vars */

</script>

