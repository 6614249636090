<template>
  <div class="bg-gray-900 text-gray-100" style="min-height: 100vh;" id="app">
    <router-view/>
  </div>
</template>

<script>
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import axios from 'axios';
import { store } from './store.js';
import { ref } from 'vue'
import Popper from "vue3-popper";

import { supabase } from "./supabase"

import { 
  Dialog, 
  DialogOverlay, 
  TransitionChild, 
  TransitionRoot,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  } from '@headlessui/vue'
import { 
  CheckCircleIcon,
  ExclamationCircleIcon,
  BookmarkAltIcon, 
  FireIcon, 
  HomeIcon, 
  InboxIcon, 
  MenuIcon, 
  UserIcon, 
  UserGroupIcon,
  CashIcon,
  CogIcon,
  XIcon, 
  ChartBarIcon } from '@heroicons/vue/outline'

export default {
  name: 'App',
  props: {
      msg: String
  },
  components: {
    Popper,
    CheckCircleIcon,
    ExclamationCircleIcon,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    MenuIcon,
    XIcon,
    UserIcon,
  },
  mounted() {
    this.checkUrl()
    this.checkIfLoggedIn()
    this.emitter.on('check-login', d => {
      this.checkIfLoggedIn()
    });
    supabase.auth.onAuthStateChange((event, session) => {
      console.log('onAuthStateChange: ', event)
      if (event === 'SIGNED_IN') {
        this.checkIfLoggedIn()
      }
      // if (event === 'PASSWORD_RECOVERY') {
      //   this.storeState.recoverySession = session
      // }
    })
  },
  computed: {
  },
  watch:{
    $route (to, from) {
      this.doAnalytics()
    }
  },
  data() {
      return {
        store: store,
        storeState: store.state,
        
        mobileMenuOpen: false,
        showMore: false,
        user: {
          name: 'Emily Selman',
          imageUrl:
            'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
        navigation: [
          { name: 'Home', href: 'Dashboard', icon: HomeIcon },
          // { name: 'Analyze', href: 'Analyze', icon: ChartBarIcon },
          { name: 'Settings', href: 'Settings', icon: CogIcon },
          { name: 'Team', href: 'Team', icon: UserGroupIcon },
        ]
      }
  },
  methods: {
    async handleLogout() {
      this.$router.push({ name: 'Logout'})
    },
    doAnalytics() {
      var cook = {}
      cook.ref = this.getParameterByName("ref");
      cook.utm_source = this.getParameterByName("utm_source");
      cook.utm_term = this.getParameterByName("utm_term");
      cook.utm_medium = this.getParameterByName("utm_medium");
      cook.utm_campaign = this.getParameterByName("utm_campaign");
      cook.utm_landing = window.location.href.split("?")[0]
      if (cook.utm_source != "" && this.getCookie("utm_source") == "") {
          this.setCookie("utm_source", cook.utm_source);
      }
      if (cook.ref != "" && this.getCookie("ref") == "") {
          this.setCookie("ref", cook.ref);
      }

      if (cook.utm_term != "" && this.getCookie("utm_term") == "") {
          this.setCookie("utm_term", cook.utm_term);
      }

      if (cook.utm_medium != "" && this.getCookie("utm_medium") == "") {
          this.setCookie("utm_medium", cook.utm_medium);
      }

      if (cook.utm_campaign != "" && this.getCookie("utm_campaign") == "") {
          this.setCookie("utm_campaign", cook.utm_campaign);
      }

      if (cook.utm_landing != "" && this.getCookie("utm_landing") == "") {
          this.setCookie("utm_landing", cook.utm_landing);
      }
      cook.referrer = document.referrer
      if (cook.referrer != "" &&
          !cook.referrer.includes("https://wax.run/") &&
          this.getCookie("referrer") == "") {
          
          this.setCookie("referrer", cook.referrer);
      }
      cook.email = this.storeState.user.email
      cook.user_id = this.storeState.user.id
      cook.type = 'Page Visited'
      this.sendAnalytics(cook)
    },
    sendAnalytics(cook) {
      let url = this.getApiUrl('analytics')
      axios.post(url, cook)
      .then(response => {
      })
      .catch(error => {
        console.log('sendAnalytics', error)
      })
    },
    getParameterByName(name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
        var results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    },
    getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(";");

        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];

            while (c.charAt(0) == " ") {
                c = c.substring(1);
            }

            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }

        return "";
    },
    setCookie(cname, cvalue) {
        var d = new Date();
        d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000); // 30 = number of days
        var expires = "expires=".concat(d.toUTCString()); // Using the new SameSite attribute: https://web.dev/samesite-cookie-recipes/
        document.cookie = ""
            .concat(cname, "=")
            .concat(cvalue, ";")
            .concat(expires, ";path=/;Secure; domain=codesubmit.io");
    },
    calcStep() {
      console.log('calcStep: ', this.storeState.onboarding.currentStep)
      // let cards = this.storeState.cards.values
      // let hasGoals = cards.filter(c => c.details.type === 'goal').length > 0
      // let hasCriticalPath = cards.filter(c => c.details.type === 'criticalPath').length > 0
      // if (hasGoals) this.storeState.onboarding.currentStep = 1
      // if (hasCriticalPath) this.storeState.onboarding.currentStep = 2
      // CALC MORE HERE
      console.log('calcStep: ', this.storeState.onboarding.currentStep)
    },
    archiveCard(card) {
      card.is_archived = true
      this.storeState.cards.values = this.storeState.cards.values.filter(c => card.public_uuid !== c.public_uuid)
      this.$router.push({ name: 'Dashboard'})
    },
    createCard(opts) {
      this.storeState.card = store.deepCopy(this.storeState.cardTemplate)
      if (opts.type) this.storeState.card.details.type = opts.type
      if (opts.title) this.storeState.card.details.title = opts.title
      this.storeState.card.public_uuid = store.uuidv4()
      this.storeState.card.user_id = this.storeState.user.id
      this.storeState.card.details.status = this.storeState.cardMeta.taskStatuses.find(s => s.id === 'new')
      this.storeState.cards.values.unshift(this.storeState.card)
      return this.storeState.card
    },
    getPic() {
      if (this.storeState.user.oauth) {
        try {
          return this.storeState.user.oauth.oauth.userInfo.picture  
        } catch (error) {
          return false
        }
      }
    },
    addGoal(g) {
      console.log('addGoal: ', g)
      this.storeState.card = store.deepCopy(this.storeState.cardTemplate)
      this.storeState.card.public_uuid = store.uuidv4()
      this.storeState.card.user_id = this.storeState.user.id
      this.storeState.card.details.dueDate = g.dueDate
      this.storeState.card.details.goal = g.goalType
      this.storeState.cards.values.unshift(this.storeState.card)
      this.$router.push({ name: 'Card', params: { id: this.storeState.card.public_uuid }})
    },
    createCriticalPath(g) {
      console.log('createCriticalPath: ', g)
      this.storeState.card = store.deepCopy(this.storeState.cardTemplate)
      this.storeState.card.public_uuid = store.uuidv4()
      this.storeState.card.user_id = this.storeState.user.id
      this.storeState.card.details.dueDate = g.dueDate
      this.storeState.card.details.goal = g.goalType
      let domain = this.storeState.user.email.split('@')[1]
      let cpMd = `
      The steps to walking a mile are clear. Take one step, then about 1,999 more. 
      The steps to achieve your traction goal are less clear, but still enumerable. 
      Your **Critical Path** is the set of steps required to get to your goal. 
      Not all steps need not be traction related, but they should be necessary to reach your goal. Keep this high level.
      ## What needs to get done to reach your goal?
      1. Step 1
      `
      this.storeState.card.details.md = cpMd
      this.storeState.card.details.title = `Critical Path for ${domain}`
      this.storeState.cards.values.unshift(this.storeState.card)
      this.$router.push({ name: 'Card', params: { id: this.storeState.card.public_uuid }})
    },
    cmdBarRouter(url) {
      // this.$router.push({ name: 'Card', params: { id: cardId }})
      // let url = {"name": "Card", "params": {"id": "{{this_card.public_uuid}}"}}
      // let url = {"name": "Card", "params": {"id": {{this_card.public_uuid}} }}
      // card/{{this_card.public_uuid}}
      console.log('cmdBarRouter:', url)
      url = JSON.parse(url)
      url.params = url
      this.$router.push(url)
    },
    goToSettings() {
      this.$router.push({ name: 'Settings'})
    },
    goToLogin() {
      this.$router.push({ name: 'Login'})
    },
    navClick(nav) {
      if (nav.href == 'Capital') {
        window.open('https://wax.run', '_blank').focus();
        return
      }
      console.log('nav: ', nav)
      this.$router.push({ name: nav.href})
    },
    getApiUrl(endpoint) {
      if (process.env.NODE_ENV != 'production') return `https://seekwellapi.ngrok.io/${endpoint}`
      return `https://api.wax.run/${endpoint}`
    },
    checkUrl() {
      console.log('checkUrl...')
      if (this.$route.query.code) {
        console.log('checkUrl code...')
        if (this.storeState.isLoggedIn) {
          // send to backend secret store
          let userLoginEvent = this.emitter.emit('user-login');
          const path = this.getApiUrl('update_secret')
          let secretType = this.$route.name.toLowerCase().replace('2', '')
          let d = {
            user: this.storeState.user, 
            code: this.$route.query.code,
            type: secretType
            }
          axios.post(path, d)
            .then((res) => {
              this.updatingStripeKey = false
              console.log('got update_secret: ', res.data)
            })
            .catch((error) => {
              console.error(error)
            })
        } else {
          // this.$router.push({ name: 'Login', query: { goto: 'Landing', slackCode:  this.storeState.slackCode}})
        }
      }
    },
    goToCard(card, event) {
      console.log('goToCard: ', card)
      this.$router.push({ name: 'Card', params: { id: card.public_uuid }})
    },
    async getSettings() {
      console.log('getSettings...')
      try {
        const result = await supabase.from('profiles').select().single()
        console.log('getSettings', result)
        if (result.error) {
          console.log('error', result.error)
          return
        } else {
          this.storeState.settings = store.addMissingAttributesSettings(result.data.details)
          console.log('set settings', this.storeState.settings)
        }
      } catch (err) {
        console.error('Error retrieving data from db', err)
      }
    },
    async checkIfLoggedIn() {
      if (window.location.href.includes('/public')) {
        console.log('checkIfLoggedIn, on public...')
        this.storeState.showLanding = false
        this.storeState.checkedLogin = true
        return
      }
      if (window.location.href.includes('/twitter')) {
        console.log('checkIfLoggedIn, on twitter...')
        this.storeState.showLanding = false
        this.storeState.checkedLogin = true
        return
      }
      if (window.location.href.includes('/terms')) {
        console.log('checkIfLoggedIn, on terms...')
        this.storeState.showLanding = false
        this.storeState.checkedLogin = true
        return
      }
      if (window.location.href.includes('/privacy')) {
        console.log('checkIfLoggedIn, on privacy...')
        this.storeState.showLanding = false
        this.storeState.checkedLogin = true
        return
      }
      if ((this.$route.query.type && 
            this.$route.query.type === 'recovery') ||
            (window.location.href.includes('type=recovery'))
        ) {
        console.log('checkUrl, found recovery...')
        var url_string = window.location.href
        url_string = url_string.replace('#access_token', '?access_token')
        var url = new URL(url_string);
        this.storeState.recoverySession = {
          accessToken: url.searchParams.get("access_token"),
          expiresIn: url.searchParams.get("expires_in"),
          tokenType: url.searchParams.get("token_type"),
          type: url.searchParams.get("type"),
        }
        this.storeState.doingRecovery = true
        this.$router.push({ name: 'Login'})
      }
      if (this.storeState.doingRecovery) {
        this.storeState.checkedLogin = true
        this.storeState.showLanding = false
        return
      }
      console.log('checkIfLoggedIn1...', this.storeState.isLoggedIn)
      setTimeout(() => this.storeState.checkedLogin = true, 2000);
      if (!this.storeState.isLoggedIn) {
        const supaUser = supabase.auth.user()
        if (supaUser) {
          this.storeState.user = supaUser
          this.storeState.isLoggedIn = true
          this.storeState.showLanding = false
        }
        console.log('checkIfLoggedIn2...', this.storeState.isLoggedIn)
      }
      this.storeState.checkedLogin = true
      if (this.storeState.isLoggedIn) {
        this.checkUrl()
        setTimeout(() => this.emitter.emit('user-login'), 1);
      } else {
        // this.$router.push({ name: 'Login'})
      }
    },
  }
}

/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
</script>

<style>
  @import './assets/styles/styles.css';
</style>
