// import axios from 'axios';
import _ from 'lodash';

import TwitterIcon from './components/TwitterIcon';

import { 
  UserIcon, 
  AnnotationIcon,
  HashtagIcon,
  TrendingUpIcon,
  TagIcon,
  EyeIcon,
  DocumentTextIcon,
  LightBulbIcon,
  OfficeBuildingIcon,
  CubeIcon,
  BookOpenIcon,
  SelectorIcon,
  CheckIcon,
  CheckCircleIcon,
  DuplicateIcon,
  InformationCircleIcon,
  PencilAltIcon,
  MapIcon,
  DotsHorizontalIcon,
  QuestionMarkCircleIcon,
  InboxIcon,
  BriefcaseIcon,
  ClockIcon,
  } from '@heroicons/vue/solid'

export const store = {
  state: {
      showDebugStuffNow: false,
      isLoggedIn: false,
      checkedLogin: false,
      doingRecovery: false,
      showLanding: true,
      recoverySession: null,
      hideSidebar: false,
      twitterFlow: false,
      loading: false,
      cards: {
        ok: false,
        values: [],
      },
      view: {},
      views: {
        channel: {
          label: 'Channels',
          id: 'channel',
        },
        trending: {
          label: 'Trending',
          id: 'trending',
        },
        strategy: {
          label: 'Strategies',
          id: 'strategy',
        },
        queue: {
          label: 'Queue',
          id: 'queue',
        }
      },
      cardTemplate: {
        details: {
          type: '',
          title: '',
          description: '',
          md: '',
          editingMd: true,
          html: '',
          linked: [],
          dueDate: null,
          twitter: {
            value: null,
            data: {},
          },
          url: {},
          tweet: {
            status: {
              id: 'new',
              label: 'New (Inbox)',
              icon: InboxIcon,
              description: 'Not posted or scheduled.',
            },
            postOn: null,
            api: {
              data: {},
              updatedOn: null,
            }
          },
          completedOn: null,
          status: {},
          priority: {},
          fields: [],
          goal: {
            type: null
          },
          done: false,
          data: [
            // {
              // name: 'Revenue',
              // target: 100000,
              // deadline: someDate,
            // }
          ]
        }
      },
      card: {
        id: null,
        details: {
          title: null,
        }
      },
      onboarding: {
        currentStep: 0,
        steps: [
          // {
          //   id: 'team',
          //   todo: 'Create team',
          //   done: 'Team created!'
          // },
          {
            id: 'launchAddon',
            todo: 'Launch the Sheets addon',
            done: 'Addon launched'
          },
          {
            id: 'connectSheets',
            todo: 'Connect Google Sheets',
            done: 'Sheets connected!'
          },
          {
            id: 'whitelist',
            todo: 'Whitelist our IP address and connect to your database',
            done: 'Database connected!'
          },
          {
            id: 'inviteTeamMembers',
            todo: 'Invite coworkers to join your team',
            done: 'Team members invited!'
          },
          {
            id: 'slack',
            todo: 'Connect Slack',
            done: 'Slack connected!'
          },
          {
            id: 'python',
            todo: 'Create your first Python action',
            done: 'Python action created!'
          },
        ],
      },
      cardMeta: {
        taskStatuses: [
          {
            id: 'new',
            label: 'New (Inbox)',
            icon: InboxIcon,
            description: 'No one has started working on it.',
          },
          {
            id: 'in-progress',
            label: 'In Progress',
            icon: BriefcaseIcon,
            description: 'Someone is working on it.',
          },
          {
            id: 'done',
            label: 'Done',
            icon: CheckCircleIcon,
            description: 'Task is implemented (completed).',
          },
          {
            id: 'backlog',
            label: `Backlog (🤷)`,
            icon: QuestionMarkCircleIcon,
            description: 'No one is working on it soon.',
          },
        ],
        tweetStatuses: [
          {
            id: 'new',
            label: 'New (Inbox)',
            icon: InboxIcon,
            description: 'Not posted or scheduled.',
          },
          {
            id: 'in-queue',
            label: 'In Queue',
            icon: ClockIcon,
            description: 'Tweet is in your queue to be posted later.',
          },
          {
            id: 'posted',
            label: 'Posted',
            icon: CheckCircleIcon,
            description: 'Tweet has been posted already',
          },
        ],
        hmlRankings: [
          {
            id: 'vlow',
            label: 'Very Low',
            rank: 0,
          },
          {
            id: 'low',
            label: 'Low',
            rank: 1,
          },
          {
            id: 'medium',
            label: 'Medium',
            rank: 2,
          },
          {
            id: 'high',
            label: 'High',
            rank: 3,
          },
          {
            id: 'vhigh',
            label: 'Very High',
            rank: 4,
          },
        ],
        goals: [
          {
            id: 'mrr',
            label: 'MRR',
            format: 'currency',
            placeholder: 10000,
          },
          {
            id: 'users',
            label: 'Users',
            format: 'number',
          },
        ],
        fields: [
          {
            id: 'dueDate',
            label: 'Due On',
            type: 'date',
            cardType: ['task', 'goal'],
          },
          {
            id: 'priority',
            label: 'Priority',
            type: 'ranking',
            cardType: ['task', 'goal'],
          },
          {
            id: 'twitter',
            label: 'Twitter',
            type: 'text',
            cardType: ['person', 'company'],
          },
        ],
        types: [
          {
            id: 'strategy', 
            name: 'Strategy', 
            icon: LightBulbIcon, 
            description: `
              Social ads is a traction channel. Running ads on Twitter or Facebook is a channel 
                <span class="font-bold underline">strategy</span> within social ads
              `
          },
          {
            id: 'goal', 
            name: 'Goal', 
            icon: TrendingUpIcon, 
            description: `
              Your goal should be to be meaningful (e.g. enough to raise funding, becoming profitable, etc.)
              and quantitative. 
              <a class="font-bold" href="https://www.wax.run/card/a40054ba0d8643248052acfc4cf00bf2" target="_blank">See here for more on goals.</a>
              `
          },
          {
            id: 'channel', 
            name: 'Channel', 
            icon: HashtagIcon, 
            description: `
              Social ads is a traction <span class="font-bold underline">channel</span>. 
                Running ads on Twitter or Facebook is a channel within social ads
              `
          },
          {
            id: 'person', 
            name: 'Person', 
            icon: UserIcon, 
            description: 'A person you want to remember and tag elsewhere'
          },
          {
            id: 'quote', 
            name: 'Quote', 
            icon: AnnotationIcon, 
            description: 'A quote or snippet of information around growth'
          },
          {
            id: 'company', 
            name: 'Company', 
            icon: OfficeBuildingIcon, 
            description: 'A Company you want to remember and tag elsewhere, e.g. Segment'
          },
          {
            id: 'product', 
            name: 'Product', 
            icon: CubeIcon, 
            description: 'A Product that can help you grow, e.g. Google Trends'
          },
          {
            id: 'content', 
            name: 'Content', 
            icon: BookOpenIcon, 
            description: 'A book, podcast, blog post, etc. about growth.'
          },
          {
            id: 'task', 
            name: 'Task', 
            icon: PencilAltIcon, 
            description: 'A task related to a strategy'
          },
          {
            id: 'criticalPath', 
            name: 'Critical Path', 
            icon: MapIcon, 
            description: 'The intermediate steps (milestones) that are required to get to your goal'
          },
          {
            id: 'note', 
            name: 'Note', 
            icon: PencilAltIcon, 
            description: 'A place to keep general notes (searchable)'
          },
          {
            id: 'tweet', 
            name: 'Tweet', 
            icon: TwitterIcon, 
            description: `
              Social ads is a traction channel. Running ads on Twitter or Facebook is a channel 
                <span class="font-bold underline">strategy</span> within social ads
              `
          },
          {
            id: 'tag', 
            name: 'Tag', 
            icon: TagIcon, 
            description: 'General tags used for key terms or to categorize ideas'
          },
          // {
          //   id: 'status', 
          //   name: 'Status', 
          //   icon: SelectorIcon, 
          //   description: 'Used to mark the status of other cards'
          // },
        ]
      },
      metricData: {},
      events: {data: []},
      user: {},
      slackCode: false,
      settings: {
        fromSupa: false,
        google: {
          ok: false,
        },
        twitter: {
          handle: null,
          data: {},
          lists: [
            {
              id: null,
              id_str: null,
            }
          ],
          searches: [
            {
              id: null,
              id_str: null,
              q: ''
            }
          ]
        },
        sheets: {},
        phoneNumber: null,
        notifications: {
          alerts: {
            sms: true,
            slack: true,
            email: false,
          },
          weekly: {
            sms: false,
            slack: true,
            email: true,
          },
          monthly: {
            sms: false,
            slack: true,
            email: true,
          },
        }
      },
      userData: {
        savedFunders: []
      },
      msg: {
        show: false,
        primary: '',
        secondary: '',
        icon: '',
        type: '',
        time: 8000,
      }
  },
  uuidv4() {
    return ([1e7] + 1e3 + 4e3 + 8e3 + 1e11).replace(/[018]/g, c =>
          (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      );
  },
  deepCopy(c) {
    return JSON.parse(JSON.stringify(c))
  },
  formatCash(cash) {
    if (cash >= 1000000) return `$${(cash/1000000).toFixed(1)}M`
    return `$${(cash/1000).toFixed(1)}K`
  },
  formatDateShort(date) {
    if (!date) return ''
    if (!date.toLocaleDateString) {
      date = new Date(date)
    }
    var options = { 
      year: 'numeric', 
      month: 'long', 
    };
    return date.toLocaleDateString("en-US", options)
  },
  showMsg(opts) {
    store.state.msg.show = true
    store.state.msg.primary = opts.primary
    store.state.msg.secondary = opts.secondary
    store.state.msg.type = opts.type
    setTimeout(() => store.state.msg.show = false, store.state.msg.time);
  },
  addMissingAttributes(card) {
    let template = JSON.parse(JSON.stringify(store.state.cardTemplate))
    return _.merge(template, card);
  },
  addMissingAttributesSettings(settings) {
    let template = JSON.parse(JSON.stringify(store.state.settings))
    return _.merge(template, settings);
  },
  timeSinceToStr(ts) {
    if (ts.ago) {
      return `${ts.interval} ${ts.period} ago`
    }
    return `in ${ts.interval} ${ts.period}`
  },
  timeSince(date) {
    var seconds = Math.floor((new Date() - date) / 1000)
    let ret = {}
    ret.ago = seconds > 0 // if positive, then it's in the past
    seconds = Math.abs(seconds)
    var interval = seconds / 31536000
    var period = ''
    if (interval > 1) {
      ret.period = 'years'
      ret.interval = Math.floor(interval)
      return ret
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      ret.period = 'months'
      ret.interval = Math.floor(interval)
      return ret
    }
    interval = seconds / 86400;
    if (interval > 1) {
      ret.period = 'days'
      ret.interval = Math.floor(interval)
      return ret
    }
    interval = seconds / 3600;
    if (interval > 1) {
      ret.period = 'hours'
      ret.interval = Math.floor(interval)
      return ret
    }
    interval = seconds / 60;
    if (interval > 1) {
      ret.period = 'minutes'
      ret.interval = Math.floor(interval)
      return ret
    }
    ret.period = 'minutes'
    ret.interval = Math.floor(interval)
    return ret
  },
}